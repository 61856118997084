/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum InvoiceSection {
    RECEIVER = 'RECEIVER',
    SUPPLIER = 'SUPPLIER',
    IBANS = 'IBANS',
    INVOICE_DATA = 'INVOICE_DATA',
    PRODUCTS = 'PRODUCTS',
}
