import { Box, Typography } from '@mui/material';
import { GridFooterContainer, GridPagination } from '@mui/x-data-grid';
import { useAtom } from 'jotai';

import { useTranslations } from 'context/TranslationContext';

import { FilterType } from 'utils/enums/FilterType';

import { filterTypeAtom } from 'state/state';

export const CustomGridFooter = () => {
  const { translate } = useTranslations();

  const [filterType] = useAtom(filterTypeAtom);

  return (
    <GridFooterContainer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0 16px',
          width: '100%'
        }}
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box
            sx={{
              height: 25,
              width: 25,
              background: '#078dee12',
              ml: 1,
              mr: 0.5,
              border: '1px solid #078dee33'
            }}
          />
          <Typography fontSize={12}>{translate('labels.booked')}</Typography>
        </Box>

        <GridPagination sx={{ flexShrink: 0 }} />
      </Box>
    </GridFooterContainer>
  );
};
