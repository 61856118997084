/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ResourceTypes {
    AMOUNTS = 'AMOUNTS',
    APPROVER_SETTINGS = 'APPROVER_SETTINGS',
    BANK_STATEMENTS = 'BANK_STATEMENTS',
    BUDGETS = 'BUDGETS',
    COMPANIES = 'COMPANIES',
    COUNTERPARTIES = 'COUNTERPARTIES',
    DASHBOARD = 'DASHBOARD',
    EXPENSE_TYPES = 'EXPENSE_TYPES',
    IBANS = 'IBANS',
    INVOICE_ACCOUNTING = 'INVOICE_ACCOUNTING',
    INVOICE_APPROVAL = 'INVOICE_APPROVAL',
    INVOICE_PAYMENTS = 'INVOICE_PAYMENTS',
    INVOICES = 'INVOICES',
    PAYROLL = 'PAYROLL',
    USERS = 'USERS',
}
