import { useCallback } from 'react';

import { DropDownHelperService, ExpenseTypeService } from 'openapi';

import { useInterceptor } from 'hooks/useInterceptor';

export const useAdditionalFieldsController = () => {
  const { interceptRequest } = useInterceptor();

  const { getAllDropDownExpenseTypes } = ExpenseTypeService;

  const { getAllDropDownCounterparties, getAllCurrenciesForDropDown } =
    DropDownHelperService;

  const getDropdownCounterparties = useCallback(
    (searchParam?: string) =>
      interceptRequest(getAllDropDownCounterparties, {}, searchParam),
    [getAllDropDownCounterparties]
  );

  const getExpenseTypes = useCallback(
    (id: number) => interceptRequest(getAllDropDownExpenseTypes, {}, id),
    [getAllDropDownExpenseTypes]
  );

  const getCurrencies = useCallback(
    () => interceptRequest(getAllCurrenciesForDropDown, {}),
    [getAllCurrenciesForDropDown]
  );

  return {
    getDropdownCounterparties,
    getExpenseTypes,
    getCurrencies
  };
};
