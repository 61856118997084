import { Paper } from '@mui/material';
import { DataGrid, GridCellParams } from '@mui/x-data-grid';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useCounterPartyController } from 'api/controllers/CounterPartyController';
import { useUserController } from 'api/controllers/UserController';

import { CounterpartyApproval, User } from 'openapi';

import { UpdateCounterpartyApproval } from 'components/forms/UpdateCounterpartyApproval';
import { Modal } from 'components/shared/Modal/Modal';

import { useTranslations } from 'context/TranslationContext';

import { useModal } from 'hooks/useModal';

import { getCounterpartiesApprovalsColumns } from 'utils/helpers/approvalsHelpers';

import { commonDataGridContainerStyle } from 'styles/components/DataGridStyle';
import { invoicesDataGrid } from 'styles/components/InvoicesDataGridStyle';

import { CounterpartyApprovalPatch } from '../../openapi/models/CounterpartyApprovalPatch';

export const CounterpartiesApproval = () => {
  const { companyId } = useParams();
  const { translate } = useTranslations();

  const { getCounterpartyApprovalsByCompanyId, updateCounterpartyApproval } =
    useCounterPartyController();
  const { getApprovers } = useUserController();

  const [counterparties, setCounterparties] = useState<CounterpartyApproval[]>(
    []
  );
  const [counterpartyForEdit, setCounterpartyForEdit] =
    useState<CounterpartyApprovalPatch>();

  const {
    openModal: openEditModal,
    closeModal: closeEditModal,
    isOpen: isEditModalOpen
  } = useModal();

  const handleEditClick = (params: GridCellParams) => {
    setCounterpartyForEdit(params.row);
    openEditModal();
  };

  const handleCloseEditModal = () => {
    setCounterpartyForEdit(undefined);
    closeEditModal();
  };

  const fetchCounterpartyApprovals = useCallback(async () => {
    const result = await getCounterpartyApprovalsByCompanyId(Number(companyId));
    setCounterparties(result);
  }, [getCounterpartyApprovalsByCompanyId]);

  const [approvers, setApprovers] = useState<User[]>([]);

  const fetchApprovers = useCallback(async () => {
    const result = await getApprovers(Number(companyId));
    setApprovers(result);
  }, []);

  useEffect(() => {
    fetchCounterpartyApprovals();
    fetchApprovers();
  }, [fetchCounterpartyApprovals, fetchApprovers]);

  const handleSave = useCallback(
    async (values: CounterpartyApprovalPatch) => {
      await updateCounterpartyApproval(Number(companyId), values.id!, values);
      handleCloseEditModal();

      fetchCounterpartyApprovals();
    },
    [companyId]
  );

  const columns = getCounterpartiesApprovalsColumns(translate);

  return (
    <Paper elevation={4} sx={commonDataGridContainerStyle}>
      <DataGrid
        rows={counterparties}
        columns={columns}
        disableRowSelectionOnClick
        onCellClick={handleEditClick}
        sx={invoicesDataGrid}
        pageSizeOptions={[]}
        localeText={{
          noRowsLabel: translate('labels.noData')
        }}
      />

      <Modal
        isOpen={isEditModalOpen}
        hide={handleCloseEditModal}
        headerTitle={translate('approvals.editCounterparty')}
        maxWidth="sm"
      >
        <UpdateCounterpartyApproval
          onSubmit={handleSave}
          initialValues={counterpartyForEdit as CounterpartyApproval}
          approvers={approvers}
        />
      </Modal>
    </Paper>
  );
};
