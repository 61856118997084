import { Checklist, AccountBalanceWallet } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import PaymentIcon from '@mui/icons-material/Payment';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import { Box, Button, Tooltip } from '@mui/material';
import {
  GridToolbarContainer,
  GridRowSelectionModel,
  GridToolbar as DefaultGridToolbar,
  GridRowsProp
} from '@mui/x-data-grid';
import { useAtom } from 'jotai';
import { useMemo } from 'react';

import { DocumentType, InvoiceStages } from 'openapi';

import { useTranslations } from 'context/TranslationContext';

import {
  gridToolbarContainerStyle,
  gridToolbarStyle,
  defaultGridToolbarStyle,
  gridToolbarOutsideContainerStyle
} from 'styles/components/DataGridStyle';

import { userAtom } from 'state/state';

interface GridToolbarProps {
  readonly handleAddClick?: () => void;
  readonly handleDeleteClick?: () => void;
  readonly handleExportForPaymentClick?: () => void;
  readonly handleExportExcelClick?: () => void;
  readonly handleUploadInvoice?: (
    fileData: FileList | File[],
    field: string
  ) => Promise<void>;
  readonly handleAccountClick?: () => void;
  readonly handlePayClick?: () => void;
  readonly handleApproveClick?: () => void;
  readonly handleRejectClick?: () => void;
  readonly handleDownloadClick?: () => void;
  readonly handleCreateClick?: () => void;
  readonly selectedRows?: GridRowSelectionModel;
  readonly isExportDisabled?: boolean;
  readonly isDefaultToolbarHidden?: boolean;
  readonly isDeleteDisabled?: boolean;
  readonly deleteButtonTooltip?: string;
  readonly entries: GridRowsProp;
}

export const GridToolbar = ({
  handleAddClick,
  handleDeleteClick,
  handleExportForPaymentClick,
  handleExportExcelClick,
  handleUploadInvoice,
  handleAccountClick,
  handlePayClick,
  handleApproveClick,
  handleRejectClick,
  handleDownloadClick,
  handleCreateClick,
  selectedRows,
  isExportDisabled,
  isDefaultToolbarHidden,
  entries,
  isDeleteDisabled,
  deleteButtonTooltip
}: GridToolbarProps) => {
  const { translate } = useTranslations();
  const [user] = useAtom(userAtom);

  const justifyContent =
    selectedRows && selectedRows.length > 0 ? 'space-between' : 'flex-end';
  const noRowsAreSelected = selectedRows && selectedRows.length === 0;
  const filteredEntries = entries.filter((entry: any) =>
    selectedRows?.includes(entry.id)
  );
  const shouldDisableDelete = filteredEntries.some(
    (entry: any) => !entry.isDeletable
  );
  const shouldDisablePay = filteredEntries.some(
    (entry: any) => entry.stage !== InvoiceStages.APPROVED
  );
  const isNotApprovedOrPaid = filteredEntries.some(
    (entry) =>
      entry.stage !== InvoiceStages.APPROVED &&
      entry.stage !== InvoiceStages.PAID
  );
  const isAlreadyBooked = filteredEntries.some((entry) => entry.isAccounted);
  const shouldDisableBook = isNotApprovedOrPaid || isAlreadyBooked;
  const shouldDisableApprove = filteredEntries.some(
    (entry: any) =>
      !entry.approvers?.includes(user?.name) ||
      entry.stage !== InvoiceStages.VALIDATED
  );
  const shouldDisableExport = filteredEntries.some(
    (entry: any) => entry.stage !== InvoiceStages.APPROVED
  );
  const shouldDisableDownload = filteredEntries.some(
    (entry: any) =>
      entry.stage === InvoiceStages.UPLOADED ||
      entry.documentType === DocumentType.NO_DOCUMENT
  );

  const deleteButtonTooltipText = useMemo(() => {
    if (noRowsAreSelected) {
      return translate('warnings.noSelectedRows');
    }
    if (shouldDisableDelete || isDeleteDisabled) {
      return deleteButtonTooltip;
    }

    return '';
  }, [selectedRows, entries, translate]);

  const payButtonTooltipText = useMemo(() => {
    if (noRowsAreSelected) {
      return translate('warnings.noSelectedRows');
    }
    if (shouldDisablePay) {
      return translate('messages.disabledPayInvoices');
    }

    return '';
  }, [selectedRows, entries, translate]);

  const bookButtonTooltipText = useMemo(() => {
    if (noRowsAreSelected) {
      return translate('warnings.noSelectedRows');
    }
    if (isNotApprovedOrPaid) {
      return translate('messages.disabledBookedInvoicesNotApprovedOrPaird');
    }
    if (isAlreadyBooked) {
      return translate('messages.disabledBookedInvoicesAlreadyBooked');
    }

    return '';
  }, [selectedRows, entries, translate]);

  const approveButtonTooltipText = useMemo(() => {
    if (noRowsAreSelected) {
      return translate('warnings.noSelectedRows');
    }
    if (shouldDisableApprove) {
      return translate('messages.disabledApproveInvoices');
    }

    return '';
  }, [selectedRows, entries, translate]);

  const rejectButtonTooltipText = useMemo(() => {
    if (noRowsAreSelected) {
      return translate('warnings.noSelectedRows');
    }
    if (shouldDisableApprove) {
      return translate('messages.disabledRejectInvoices');
    }

    return '';
  }, [selectedRows, entries, translate]);

  const exportButtonTooltipText = useMemo(() => {
    if (noRowsAreSelected) {
      return translate('warnings.noSelectedRows');
    }
    if (shouldDisableExport) {
      return translate('messages.disabledPayInvoices');
    }
    return '';
  }, [selectedRows, entries, translate]);

  const downloadButtonTooltipText = useMemo(() => {
    if (noRowsAreSelected) {
      return translate('warnings.noSelectedRows');
    }
    if (shouldDisableDownload) {
      return translate('messages.disabledDownloadInvoices');
    }
    return '';
  }, [selectedRows, entries, translate]);

  return (
    <Box
      sx={{
        ...gridToolbarContainerStyle
      }}
    >
      <Box sx={gridToolbarOutsideContainerStyle}>
        {handleDeleteClick && (
          <Tooltip title={deleteButtonTooltipText}>
            <span>
              <Button
                onClick={handleDeleteClick}
                color="error"
                disabled={
                  noRowsAreSelected || shouldDisableDelete || isDeleteDisabled
                }
                startIcon={
                  <DeleteTwoToneIcon
                    sx={{
                      width: '1.5rem',
                      height: '1.5rem'
                    }}
                  />
                }
              >
                {translate('buttons.delete')}
              </Button>
            </span>
          </Tooltip>
        )}
        {handleApproveClick && (
          <Tooltip title={approveButtonTooltipText}>
            <span>
              <Button
                onClick={handleApproveClick}
                color="primary"
                disabled={noRowsAreSelected || shouldDisableApprove}
                startIcon={
                  <Checklist
                    sx={{
                      mr: '0.2rem'
                    }}
                  />
                }
              >
                {translate('buttons.approve')}
              </Button>
            </span>
          </Tooltip>
        )}
        {handleRejectClick && (
          <Tooltip title={rejectButtonTooltipText}>
            <span>
              <Button
                onClick={handleRejectClick}
                color="primary"
                disabled={noRowsAreSelected || shouldDisableApprove}
                startIcon={
                  <PlaylistRemoveIcon
                    sx={{
                      width: '1.5rem',
                      height: '1.5rem',
                      mr: '0.2rem'
                    }}
                  />
                }
              >
                {translate('buttons.reject')}
              </Button>
            </span>
          </Tooltip>
        )}
        {handlePayClick && (
          <Tooltip title={payButtonTooltipText}>
            <span>
              <Button
                onClick={handlePayClick}
                color="primary"
                disabled={noRowsAreSelected || shouldDisablePay}
                startIcon={
                  <PaymentIcon
                    sx={{
                      width: '1.5rem',
                      height: '1.5rem',
                      mr: '0.2rem'
                    }}
                  />
                }
              >
                {translate('buttons.pay')}
              </Button>
            </span>
          </Tooltip>
        )}
        {handleAccountClick && (
          <Tooltip title={bookButtonTooltipText}>
            <span>
              <Button
                onClick={handleAccountClick}
                color="primary"
                disabled={noRowsAreSelected || shouldDisableBook}
                startIcon={
                  <AccountBalanceWallet
                    sx={{
                      width: '1.5rem',
                      height: '1.5rem',
                      mr: '0.2rem'
                    }}
                  />
                }
              >
                {translate('buttons.book')}
              </Button>
            </span>
          </Tooltip>
        )}
        {handleExportForPaymentClick && (
          <Tooltip title={exportButtonTooltipText}>
            <span>
              <Button
                color="primary"
                onClick={handleExportForPaymentClick}
                disabled={noRowsAreSelected || shouldDisableExport}
                startIcon={
                  <SystemUpdateAltIcon
                    sx={{
                      width: '1.5rem',
                      height: '1.5rem',
                      mr: '0.2rem'
                    }}
                  />
                }
              >
                {translate('buttons.exportForPayment')}
              </Button>
            </span>
          </Tooltip>
        )}
        {handleDownloadClick && (
          <Tooltip title={downloadButtonTooltipText}>
            <span>
              <Button
                color="primary"
                onClick={handleDownloadClick}
                disabled={noRowsAreSelected || shouldDisableDownload}
                startIcon={
                  <DownloadIcon
                    sx={{
                      width: '1.5rem',
                      height: '1.5rem',
                      mr: '0.2rem'
                    }}
                  />
                }
              >
                {translate('buttons.download')}
              </Button>
            </span>
          </Tooltip>
        )}
      </Box>
      <Box
        sx={{
          ...gridToolbarContainerStyle,
          justifyContent: isDefaultToolbarHidden ? 'flex-end' : 'space-between'
        }}
      >
        {!isDefaultToolbarHidden && (
          <DefaultGridToolbar sx={defaultGridToolbarStyle} />
        )}
        <GridToolbarContainer sx={{ ...gridToolbarStyle, justifyContent }}>
          {handleAddClick && (
            <Button
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleAddClick}
            >
              {translate('buttons.add')}
            </Button>
          )}
          {handleExportExcelClick && (
            <Button
              variant="outlined"
              color="primary"
              onClick={handleExportExcelClick}
              disabled={isExportDisabled}
            >
              {translate('buttons.exportExcel')}
            </Button>
          )}
          {handleCreateClick && (
            <Button
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleCreateClick}
            >
              {translate('buttons.create')}
            </Button>
          )}
          {handleUploadInvoice && (
            <Button
              color="primary"
              startIcon={<FileUploadIcon />}
              component="label"
            >
              {translate('buttons.upload')}
              <input
                hidden
                accept=".pdf, .png, .jpg, .jpeg"
                type="file"
                name="invoice"
                multiple
                onChange={(e) => {
                  if (!handleUploadInvoice) {
                    return;
                  }
                  handleUploadInvoice(e.target.files as FileList, 'button');
                  e.target.value = '';
                }}
              />
            </Button>
          )}
        </GridToolbarContainer>
      </Box>
    </Box>
  );
};
