/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DocumentUrlResponse } from '../models/DocumentUrlResponse';
import type { IdList } from '../models/IdList';
import type { InvoiceDelete } from '../models/InvoiceDelete';
import type { InvoiceFiltersResponse } from '../models/InvoiceFiltersResponse';
import type { InvoiceListResponse } from '../models/InvoiceListResponse';
import type { InvoicePageResponse } from '../models/InvoicePageResponse';
import type { InvoicePatch } from '../models/InvoicePatch';
import type { InvoiceQueryFilters } from '../models/InvoiceQueryFilters';
import type { InvoiceResponse } from '../models/InvoiceResponse';
import type { InvoiceRowResponse } from '../models/InvoiceRowResponse';
import type { InvoiceSection } from '../models/InvoiceSection';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InvoiceService {

    /**
     * This method returns the list of invoices by company id
     * @param id The company id
     * @param stage Stage of invoices
     * @returns InvoiceListResponse OK
     * @throws ApiError
     */
    public static getInvoicesByCompanyId(
        id: number,
        stage?: string,
    ): CancelablePromise<InvoiceListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{id}/invoices',
            path: {
                'id': id,
            },
            query: {
                'stage': stage,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method is used to upload invoices for AI extraction
     * @param id The company id
     * @param formData Files for extraction
     * @returns void
     * @throws ApiError
     */
    public static uploadInvoice(
        id: number,
        formData: {
            files?: Array<Blob>;
        },
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/companies/{id}/invoices',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method returns the list of invoice filters
     * @returns InvoiceFiltersResponse OK
     * @throws ApiError
     */
    public static getInvoiceFilters(): CancelablePromise<InvoiceFiltersResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/invoices/filters',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method returns the list of filtered invoices
     * @param requestBody Files for extraction
     * @returns InvoicePageResponse OK
     * @throws ApiError
     */
    public static getInvoicesByFilters(
        requestBody: InvoiceQueryFilters,
    ): CancelablePromise<InvoicePageResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/invoices/filters',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method returns the list of invoice filters by company id
     * @param id The company id
     * @returns InvoiceFiltersResponse OK
     * @throws ApiError
     */
    public static getInvoiceFiltersByCompanyId(
        id: number,
    ): CancelablePromise<InvoiceFiltersResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{id}/invoices/filters',
            path: {
                'id': id,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method returns the list of filtered invoices by company id
     * @param id The company id
     * @param requestBody Files for extraction
     * @returns InvoicePageResponse OK
     * @throws ApiError
     */
    public static getInvoicesByCompanyIdAndFilters(
        id: number,
        requestBody: InvoiceQueryFilters,
    ): CancelablePromise<InvoicePageResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/companies/{id}/invoices/filters',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method returns an invoice by id
     * @param companyId The id of the company which is receiver of the invoice
     * @param invoiceId The invoice id (not to be confused with invoice number)
     * @returns InvoiceResponse OK
     * @throws ApiError
     */
    public static getInvoiceById(
        companyId: number,
        invoiceId: number,
    ): CancelablePromise<InvoiceResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{companyId}/invoices/{invoiceId}',
            path: {
                'companyId': companyId,
                'invoiceId': invoiceId,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method create an invoice without file
     * @param companyId The company id
     * @param invoiceId The invoice id
     * @param requestBody The invoice object
     * @returns InvoiceResponse OK
     * @throws ApiError
     */
    public static updateInvoiceById(
        companyId: number,
        invoiceId: number,
        requestBody: InvoicePatch,
    ): CancelablePromise<InvoiceResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/companies/{companyId}/invoices/{invoiceId}',
            path: {
                'companyId': companyId,
                'invoiceId': invoiceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method approves or disapproves an invoice by id
     * @param companyId The id of the company which is receiver of the invoice
     * @param invoiceId The invoice id (not to be confused with invoice number)
     * @returns InvoiceResponse OK
     * @throws ApiError
     */
    public static toggleInvoiceApprovalById(
        companyId: number,
        invoiceId: number,
    ): CancelablePromise<InvoiceResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/companies/{companyId}/invoices/{invoiceId}/approve',
            path: {
                'companyId': companyId,
                'invoiceId': invoiceId,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method returns an invoice row by id
     * @param invoiceId The invoice id (not to be confused with invoice number)
     * @returns InvoiceRowResponse OK
     * @throws ApiError
     */
    public static getInvoiceRowById(
        invoiceId: number,
    ): CancelablePromise<InvoiceRowResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/invoices/{invoiceId}/row',
            path: {
                'invoiceId': invoiceId,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method deletes multiple invoices by id
     * @param requestBody The invoice ids to delete
     * @returns void
     * @throws ApiError
     */
    public static deleteInvoicesByIds(
        requestBody: InvoiceDelete,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/invoices',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Update an existing invoice
     * Updates and verifies an invoice section
     * @param companyId The company id
     * @param invoiceId The invoice id
     * @param invoiceSection The invoice section
     * @param requestBody The updated data for the invoice
     * @returns InvoiceResponse OK
     * @throws ApiError
     */
    public static updateInvoiceSectionById(
        companyId: number,
        invoiceId: number,
        invoiceSection: InvoiceSection,
        requestBody: InvoicePatch,
    ): CancelablePromise<InvoiceResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/companies/{companyId}/invoices/{invoiceId}/invoice-sections/{invoiceSection}',
            path: {
                'companyId': companyId,
                'invoiceId': invoiceId,
                'invoiceSection': invoiceSection,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Update an existing invoice
     * Updates and verifies an invoice section
     * @param companyId The company id
     * @param invoiceId The invoice id
     * @param invoiceSection The invoice section
     * @param requestBody The updated data for the invoice
     * @returns InvoiceResponse OK
     * @throws ApiError
     */
    public static verifyInvoiceSectionById(
        companyId: number,
        invoiceId: number,
        invoiceSection: InvoiceSection,
        requestBody: InvoicePatch,
    ): CancelablePromise<InvoiceResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/companies/{companyId}/invoices/{invoiceId}/invoice-sections/{invoiceSection}',
            path: {
                'companyId': companyId,
                'invoiceId': invoiceId,
                'invoiceSection': invoiceSection,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Unverify an invoice section
     * @param companyId The company id
     * @param invoiceId The invoice id
     * @param invoiceSection The invoice section to verify
     * @returns void
     * @throws ApiError
     */
    public static unverifyInvoiceSectionById(
        companyId: number,
        invoiceId: number,
        invoiceSection: InvoiceSection,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/companies/{companyId}/invoices/{invoiceId}/invoice-sections/{invoiceSection}/unverify',
            path: {
                'companyId': companyId,
                'invoiceId': invoiceId,
                'invoiceSection': invoiceSection,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                409: `CONFLICT`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Returns the url of the invoice document
     * @param id The invoice id
     * @returns DocumentUrlResponse OK
     * @throws ApiError
     */
    public static getInvoicePdfById(
        id: number,
    ): CancelablePromise<DocumentUrlResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/invoices/{id}/document',
            path: {
                'id': id,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * Re-prompt an invoice
     * @param id The invoice id
     * @returns void
     * @throws ApiError
     */
    public static repromptInvoiceById(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/invoices/{id}/reprompt',
            path: {
                'id': id,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method is used to set the isAccounted field of set invoices to true
     * @param requestBody The invoice id (not to be confused with invoice number)
     * @returns void
     * @throws ApiError
     */
    public static setIsAccountedToTrue(
        requestBody: IdList,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/invoices/account',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method is used to pay the invoices
     * @param requestBody The invoice id (not to be confused with invoice number)
     * @returns void
     * @throws ApiError
     */
    public static payInvoices(
        requestBody: IdList,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/invoices/pay',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method is used to approve the invoices
     * @param requestBody The invoice id (not to be confused with invoice number)
     * @returns void
     * @throws ApiError
     */
    public static approveInvoices(
        requestBody: IdList,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/invoices/approve',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method is used to reject the invoices
     * @param requestBody The invoice id (not to be confused with invoice number)
     * @returns void
     * @throws ApiError
     */
    public static rejectInvoices(
        requestBody: IdList,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/invoices/reject',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method create an invoice without file
     * @param companyId The company id
     * @returns InvoiceResponse OK
     * @throws ApiError
     */
    public static createWithoutFile(
        companyId: number,
    ): CancelablePromise<InvoiceResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/companies/{companyId}/invoices/create-without-file',
            path: {
                'companyId': companyId,
            },
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method approves or disapproves an invoice by id
     * @param companyId The id of the company which is receiver of the invoice
     * @param invoiceId The invoice id (not to be confused with invoice number)
     * @param requestBody The updated data for the invoice
     * @returns InvoiceResponse OK
     * @throws ApiError
     */
    public static verifyWithoutFileById(
        companyId: number,
        invoiceId: number,
        requestBody: InvoicePatch,
    ): CancelablePromise<InvoiceResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/companies/{companyId}/invoices/{invoiceId}/verify-without-file',
            path: {
                'companyId': companyId,
                'invoiceId': invoiceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                404: `NOT FOUND`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

}
