import { Atom } from 'jotai';

import { InvoiceQueryFilters } from 'openapi';

import { COMPANY_PARAMETER } from 'utils/constants/constants';
import { FilterType } from 'utils/enums/FilterType';
import { AppRoutesEnum } from 'utils/routes';

import {
  invoicesFiltersAtom,
  invoicesFiltersAtomForApproval,
  invoicesFiltersAtomForAccounting,
  invoicesFiltersAtomForPayment
} from 'state/state';

export const getInvoicesFiltersAtomByType = (
  filterType?: FilterType
): Atom<InvoiceQueryFilters> => {
  switch (filterType) {
    case FilterType.Approval:
      return invoicesFiltersAtomForApproval;
    case FilterType.Accounting:
      return invoicesFiltersAtomForAccounting;
    case FilterType.Payment:
      return invoicesFiltersAtomForPayment;
    default:
      return invoicesFiltersAtom;
  }
};

const ROUTES_ALL = {
  [FilterType.Initial]: AppRoutesEnum.INVOICES,
  [FilterType.Approval]: AppRoutesEnum.INVOICES_APPROVAL,
  [FilterType.Accounting]: AppRoutesEnum.INVOICES_ACCOUNTING,
  [FilterType.Payment]: AppRoutesEnum.INVOICES_PAYMENT
};

const ROUTES_SINGLE = {
  [FilterType.Initial]: AppRoutesEnum.COMPANY_INVOICES,
  [FilterType.Approval]: AppRoutesEnum.COMPANY_INVOICES_APPROVAL,
  [FilterType.Accounting]: AppRoutesEnum.COMPANY_INVOICES_ACCOUNTING,
  [FilterType.Payment]: AppRoutesEnum.COMPANY_INVOICES_PAYMENT
};

export const getRouteForBackNavigation = (
  state: string,
  filterType: FilterType,
  companyId?: string
) => {
  let route = '';
  if (state === AppRoutesEnum.INVOICES) {
    route = ROUTES_ALL[filterType];
  } else {
    route = ROUTES_SINGLE[filterType].replace(':companyId', String(companyId));
  }

  return route;
};
