import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {
  ClearIcon,
  DatePicker,
  LocalizationProvider
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useAtom } from 'jotai';
import moment from 'moment';
import { ChangeEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { InvoiceFilters, DocumentType } from 'openapi';

import { useTranslations } from 'context/TranslationContext';

import { DATE_FORMATS } from 'utils/constants/constants';
import {
  ALL_COMPANY_INVOICES_FILTER_FIELDS,
  FILTERS_DEFAULT_VALUES,
  INVOICES_FILTER_FIELDS
} from 'utils/constants/invoices';
import {
  shouldDisableEndDate,
  shouldDisableStartDate
} from 'utils/helpers/dates';
import { getInvoicesFiltersAtomByType } from 'utils/helpers/filtersHelpers';
import { processMoneyInput } from 'utils/helpers/moneyHelper';
import { InvoicesFiltersForm } from 'utils/interfaces/InvoiceProps';
import { getFormFilters } from 'utils/mappers/invoice';

import { labelSx } from 'styles/components/Common';

import { filterTypeAtom } from 'state/state';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface InvoicesFilterPanelProps {
  filtersData?: InvoiceFilters;
  areFiltersVisible?: boolean;
  isInAllCompanies?: boolean;
  handleApplyFilters: (data: InvoicesFiltersForm | null) => void;
}

export const InvoicesFilterPanel = ({
  filtersData,
  areFiltersVisible,
  isInAllCompanies,
  handleApplyFilters
}: InvoicesFilterPanelProps) => {
  const [filterType, setFilterType] = useAtom(filterTypeAtom);
  const [filters, setFilters] = useAtom(
    getInvoicesFiltersAtomByType(filterType)
  );
  const { control, handleSubmit, reset, getValues, setValue } =
    useForm<InvoicesFiltersForm>({
      defaultValues: { ...FILTERS_DEFAULT_VALUES },
      values: getFormFilters(filters)
    });

  const { translate } = useTranslations();

  const [counterPartyOptions, setCounterPartyOptions] = useState(
    filtersData || {}
  );
  const [isInvoiceDateFromOpen, setInvoiceDateFromOpen] =
    useState<boolean>(false);
  const [isInvoiceDateToOpen, setOpenInvoiceDateTo] = useState<boolean>(false);

  const [isUploadedDateFromOpen, setIsUploadedDateFromOpen] =
    useState<boolean>(false);
  const [isUploadedDateToOpen, setIsUploadedDateToOpen] =
    useState<boolean>(false);
  const [shouldShowMinMaxError, setShouldShowMinMaxError] = useState(false);
  const [accountStatus, setAccountStatus] = useState<string>(
    (filters.isAccounted || '').toString()
  );

  useEffect(() => {
    setCounterPartyOptions(filtersData || {});
    reset();
  }, [filtersData]);

  const filterFields = isInAllCompanies
    ? ALL_COMPANY_INVOICES_FILTER_FIELDS
    : INVOICES_FILTER_FIELDS;

  const handleClearAccountStatus = () => {
    setAccountStatus('');
    setValue('accounted', '');
  };

  return (
    <Paper
      elevation={4}
      className={`filters-container ${!areFiltersVisible && 'hidden'}`}
    >
      <Grid container>
        <form
          className={`filters ${!areFiltersVisible && 'hidden'}`}
          onSubmit={handleSubmit(handleApplyFilters)}
        >
          <Grid container spacing={1}>
            {filterFields.map((filter) => (
              <Grid item xs={isInAllCompanies ? 2 : 2.4} key={filter.name}>
                <Controller
                  name={filter.name}
                  control={control}
                  render={({ field }) => (
                    <FormControl sx={{ width: '100%' }} size="small" fullWidth>
                      <Autocomplete
                        {...field}
                        multiple
                        size="small"
                        options={counterPartyOptions[filter.name] || []}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option || ''}
                        onChange={(e, value) => {
                          field.onChange(value);
                          setCounterPartyOptions((previousValue) => {
                            const filteredValues = previousValue[
                              filter.name
                            ]?.filter((item) => !value.some((v) => v === item));
                            return {
                              ...previousValue,
                              [filter.name]: [
                                ...value,
                                ...(filteredValues || [])
                              ]
                            };
                          });
                        }}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option}
                          </li>
                        )}
                        renderTags={(value, getTagProps) =>
                          value
                            .slice(0, 1)
                            .map((option, index) => (
                              <Chip
                                sx={{ width: '65%' }}
                                label={option}
                                {...getTagProps({ index })}
                                disabled={index > 1}
                                size="small"
                              />
                            ))
                            .concat(
                              value.length > 1
                                ? [
                                    <Chip
                                      size="small"
                                      key="more"
                                      label={`+${value.length - 1} ${translate(
                                        'labels.more'
                                      )}`}
                                    />
                                  ]
                                : []
                            )
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={translate(filter.translationKey)}
                            placeholder={translate(filter.translationKey)}
                          />
                        )}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
            ))}

            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Grid item xs={2}>
                <Controller
                  name="invoiceDateFrom"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <DatePicker
                        sx={{ width: '100%' }}
                        format={DATE_FORMATS.displayedDateFormat}
                        open={isInvoiceDateFromOpen}
                        shouldDisableDate={(date) =>
                          shouldDisableStartDate(
                            date,
                            getValues('invoiceDateTo')
                          )
                        }
                        disableFuture
                        label={translate('labels.invoiceDateFrom')}
                        value={value ? moment(value) : null}
                        onClose={() => setInvoiceDateFromOpen(false)}
                        onChange={onChange}
                        slotProps={{
                          textField: {
                            size: 'small',
                            name: 'invoiceDateFrom',
                            onClick: () => setInvoiceDateFromOpen(true),
                            inputProps: { readOnly: true },
                            InputLabelProps: {
                              sx: labelSx
                            }
                          },
                          openPickerButton: {
                            onClick: () => setInvoiceDateFromOpen(true)
                          }
                        }}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name="invoiceDateTo"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <DatePicker
                        sx={{ width: '100%' }}
                        format={DATE_FORMATS.displayedDateFormat}
                        shouldDisableDate={(date) =>
                          shouldDisableEndDate(
                            date,
                            getValues('invoiceDateFrom')
                          )
                        }
                        disableFuture
                        open={isInvoiceDateToOpen}
                        label={translate('labels.invoiceDateTo')}
                        value={value ? moment(value) : null}
                        onClose={() => setOpenInvoiceDateTo(false)}
                        onChange={onChange}
                        slotProps={{
                          textField: {
                            size: 'small',
                            name: 'invoiceDateTo',
                            onClick: () => setOpenInvoiceDateTo(true),
                            inputProps: { readOnly: true },
                            InputLabelProps: {
                              sx: labelSx
                            }
                          },
                          openPickerButton: {
                            onClick: () => setOpenInvoiceDateTo(true)
                          }
                        }}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name="uploadedDateFrom"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <DatePicker
                        sx={{ width: '100%' }}
                        format={DATE_FORMATS.displayedDateFormat}
                        open={isUploadedDateFromOpen}
                        shouldDisableDate={(date) =>
                          shouldDisableStartDate(
                            date,
                            getValues('uploadedDateTo')
                          )
                        }
                        disableFuture
                        label={translate('labels.uploadedDateFrom')}
                        value={value ? moment(value) : null}
                        onClose={() => setIsUploadedDateFromOpen(false)}
                        onChange={onChange}
                        slotProps={{
                          textField: {
                            size: 'small',
                            name: 'uploadedDateFrom',
                            onClick: () => setIsUploadedDateFromOpen(true),
                            inputProps: { readOnly: true },
                            InputLabelProps: {
                              sx: labelSx
                            }
                          },
                          openPickerButton: {
                            onClick: () => setIsUploadedDateFromOpen(true)
                          }
                        }}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name="uploadedDateTo"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <DatePicker
                        sx={{ width: '100%' }}
                        format={DATE_FORMATS.displayedDateFormat}
                        shouldDisableDate={(date) =>
                          shouldDisableEndDate(
                            date,
                            getValues('uploadedDateFrom')
                          )
                        }
                        disableFuture
                        open={isUploadedDateToOpen}
                        label={translate('labels.uploadedDateTo')}
                        value={value ? moment(value) : null}
                        onClose={() => setIsUploadedDateToOpen(false)}
                        onChange={onChange}
                        slotProps={{
                          textField: {
                            size: 'small',
                            name: 'uploadedDateTo',
                            onClick: () => setIsUploadedDateToOpen(true),
                            inputProps: { readOnly: true },
                            InputLabelProps: {
                              sx: labelSx
                            }
                          },
                          openPickerButton: {
                            onClick: () => setIsUploadedDateToOpen(true)
                          }
                        }}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={2} height={10}>
                <Controller
                  name="amountFrom"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      fullWidth
                      value={field.value}
                      onChange={(
                        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                      ) => {
                        const newValue = processMoneyInput(
                          e.target.value,
                          field.value || ''
                        );
                        field.onChange(newValue);
                        setShouldShowMinMaxError(
                          getValues('amountTo')
                            ? Number(newValue) >
                                (Number(getValues('amountTo')) || 0)
                            : false
                        );
                      }}
                      label={translate('labels.amountFrom')}
                      InputLabelProps={{
                        sx: labelSx
                      }}
                      InputProps={{
                        endAdornment: field.value && (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              aria-label="clear text"
                              onClick={() => {
                                field.onChange('');
                                setShouldShowMinMaxError(false);
                              }}
                              edge="end"
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                />
                <FormHelperText
                  sx={{
                    color: 'red',
                    width: '250%'
                  }}
                >
                  {shouldShowMinMaxError
                    ? translate('errors.amountFromGreaterThanAmountTo')
                    : ''}
                </FormHelperText>
              </Grid>

              <Grid item xs={2}>
                <Controller
                  name="amountTo"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      fullWidth
                      value={field.value}
                      onChange={(
                        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                      ) => {
                        const newValue = processMoneyInput(
                          e.target.value,
                          field.value || ''
                        );
                        field.onChange(newValue);
                        setShouldShowMinMaxError(
                          getValues('amountFrom')
                            ? Number(newValue) <
                                (Number(getValues('amountFrom')) || 0)
                            : false
                        );
                      }}
                      label={translate('labels.amountTo')}
                      InputLabelProps={{
                        sx: labelSx
                      }}
                      InputProps={{
                        endAdornment: field.value && (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              aria-label="clear text"
                              onClick={() => field.onChange('')}
                              edge="end"
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name="accounted"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth size="small">
                      <InputLabel sx={labelSx}>
                        {translate('labels.accountedFilter')}
                      </InputLabel>
                      <Select
                        {...field}
                        value={accountStatus}
                        sx={{ textAlign: 'left' }}
                        size="small"
                        label={translate('labels.accountedFilter')}
                        placeholder={translate('labels.booked')}
                        onChange={(e) => {
                          setAccountStatus(e.target.value as string);
                          field.onChange(e.target.value);
                        }}
                        fullWidth
                        endAdornment={
                          field.value ? (
                            <InputAdornment
                              position="end"
                              sx={{ marginRight: 2.5 }}
                            >
                              <IconButton
                                edge="end"
                                size="small"
                                onClick={handleClearAccountStatus}
                              >
                                <ClearIcon sx={{ fontSize: '1.3rem' }} />
                              </IconButton>
                            </InputAdornment>
                          ) : null
                        }
                      >
                        <MenuItem value="true">
                          {translate('labels.bookedStatus')}
                        </MenuItem>
                        <MenuItem value="false">
                          {translate('labels.notBooked')}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name="documentType"
                  control={control}
                  render={({ field }) => (
                    <FormControl size="small" fullWidth>
                      <InputLabel sx={labelSx}>{`${translate(
                        'labels.documentType'
                      )}`}</InputLabel>
                      <Select
                        {...field}
                        sx={{ textAlign: 'left' }}
                        label={translate('labels.documentType')}
                        endAdornment={
                          field.value && (
                            <InputAdornment
                              position="end"
                              sx={{ marginRight: 2.5 }}
                            >
                              <IconButton
                                aria-label="reset input"
                                size="small"
                                edge="end"
                                onClick={() => field.onChange('')}
                              >
                                <ClearIcon sx={{ fontSize: '1.3rem' }} />
                              </IconButton>
                            </InputAdornment>
                          )
                        }
                      >
                        {Object.values(DocumentType).map((documentType) => (
                          <MenuItem key={documentType} value={documentType}>
                            {documentType}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
            </LocalizationProvider>
            <Grid container paddingTop={2} gap={2} paddingLeft={1}>
              <Grid item>
                <Button type="submit" variant="contained" size="small">
                  {translate('buttons.apply')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    reset();
                    setAccountStatus('');
                    handleApplyFilters(null);
                  }}
                >
                  {translate('buttons.reset')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Paper>
  );
};
