import { useCallback } from 'react';

import { UserManage, UserService } from 'openapi';

import { useErrorResultHandler } from 'hooks/useErrorResultHandler';
import { useInterceptor } from 'hooks/useInterceptor';

import { HttpStatusCodes } from 'utils/enums/HttpStatusCodes';

export const useUserController = () => {
  const { interceptRequest } = useInterceptor();

  const {
    login,
    register,
    getApproversByCompanyId,
    getAllUsers,
    createUser,
    deleteUsersByIds,
    updateUserById
  } = UserService;

  const { handleErrorResponse } = useErrorResultHandler();

  const userRegister = async () => {
    try {
      await register();
      const response = await login();

      return response;
    } catch (error: any) {
      return error;
    }
  };

  const userLogin = useCallback(async () => {
    try {
      const response = await login();

      return response.data;
    } catch (error: any) {
      if (error.body.error.status === HttpStatusCodes.ExpectationFailed) {
        const response = await userRegister();
        return response.data;
      }

      handleErrorResponse(error.body.error.status);

      return error;
    }
  }, []);

  const getApprovers = useCallback(
    async (id: number) => interceptRequest(getApproversByCompanyId, {}, id),
    [getApproversByCompanyId]
  );

  const addUser = useCallback(
    async (user: UserManage) => interceptRequest(createUser, {}, user),
    [createUser]
  );

  const updateUser = useCallback(
    (id: number, user: UserManage) =>
      interceptRequest(updateUserById, {}, id, user),
    [updateUserById]
  );

  const deleteUsers = useCallback(
    (userIds: number[]) => interceptRequest(deleteUsersByIds, {}, userIds),
    [deleteUsersByIds]
  );

  const getUsers = useCallback(async () => {
    return interceptRequest(getAllUsers, {});
  }, []);

  return {
    userLogin,
    userRegister,
    getApprovers,
    getUsers,
    addUser,
    updateUser,
    deleteUsers
  };
};
