import { useCallback } from 'react';

import {
  CounterpartyService,
  CounterpartyCreateUpdate,
  CounterpartyQueryFilters,
  CounterpartyApprovalPatch
} from 'openapi';

import { showSuccessToastMessage } from 'components/shared/Toast/Toast';

import { useTranslations } from 'context/TranslationContext';

import { useInterceptor } from 'hooks/useInterceptor';

export const useCounterPartyController = () => {
  const { translate } = useTranslations();
  const { interceptRequest } = useInterceptor();

  const {
    getCounterpartyById,
    createCounterparty,
    updateCounterparty,
    deleteCounterpartiesByIds,
    getCounterpartiesByCompanyIdAndFilters,
    getCounterpartyFiltersByCompanyId,
    getCounterpartyApprovals,
    updateCounterpartyApprovalById,
    checkCounterpartyExist
  } = CounterpartyService;

  const getSingleCounterParty = (companyId: number, counterpartyId: number) =>
    interceptRequest(getCounterpartyById, {}, companyId, counterpartyId);

  const createNewCounterParty = (id: number, body: CounterpartyCreateUpdate) =>
    interceptRequest(
      createCounterparty,
      {
        onSuccess: () =>
          showSuccessToastMessage(translate('messages.successfullyCreated'))
      },
      id,
      body
    );

  const editCounterParty = (
    id: number,
    counterpartyId: number,
    body: CounterpartyCreateUpdate
  ) =>
    interceptRequest(
      updateCounterparty,
      {
        onSuccess: () =>
          showSuccessToastMessage(translate('messages.successfullyUpdated'))
      },
      id,
      counterpartyId,
      body
    );

  const deleteCounterParties = (id: number, counterpartyIds: number[]) =>
    interceptRequest(
      deleteCounterpartiesByIds,
      {
        onSuccess: () =>
          showSuccessToastMessage(translate('messages.successfullyDeleted'))
      },
      id,
      counterpartyIds
    );

  const getFilteredCounterpartiesByCompanyId = (
    id: number,
    requestBody: CounterpartyQueryFilters
  ) =>
    interceptRequest(
      getCounterpartiesByCompanyIdAndFilters,
      {},
      id,
      requestBody
    );

  const getCounterpartyFilters = (id: number) =>
    interceptRequest(getCounterpartyFiltersByCompanyId, {}, id);

  const getCounterpartyApprovalsByCompanyId = useCallback(
    (companyId: number) =>
      interceptRequest(getCounterpartyApprovals, {}, companyId),
    [getCounterpartyApprovals]
  );

  const updateCounterpartyApproval = useCallback(
    (
      companyId: number,
      counterpartyId: number,
      body: CounterpartyApprovalPatch
    ) =>
      interceptRequest(
        updateCounterpartyApprovalById,
        {},
        companyId,
        counterpartyId,
        body
      ),
    [updateCounterpartyApprovalById]
  );

  const checkIfCounterpartyExists = useCallback(
    (name: string, registrationNumber: string, vatNumber: string) =>
      interceptRequest(
        checkCounterpartyExist,
        {},
        name,
        registrationNumber,
        vatNumber
      ),
    [checkCounterpartyExist]
  );

  return {
    getSingleCounterParty,
    createNewCounterParty,
    editCounterParty,
    deleteCounterParties,
    getFilteredCounterpartiesByCompanyId,
    getCounterpartyFilters,
    getCounterpartyApprovalsByCompanyId,
    updateCounterpartyApproval,
    checkIfCounterpartyExists
  };
};
