import { Box, Button, FormHelperText, TextField } from '@mui/material';
import { Field, Form, Formik, ErrorMessage } from 'formik';
import { useAtom } from 'jotai';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useCompanyController } from 'api/controllers/CompanyController';

import { Company, CompanyIban, Iban, ResourceTypes } from 'openapi';

import { CustomFormikFieldArray } from 'components/shared/fields/FieldArrays/CustomFormikFieldArray';
import { ConfirmationDialog } from 'components/shared/Modal/ConfirmationDialog';

import { useCompanies } from 'context/CompanyContext';
import { usePermissions } from 'context/PermissionsContext';
import { useTranslations } from 'context/TranslationContext';

import { useModal } from 'hooks/useModal';

import {
  COMPANY_PARAMETER,
  COUNTERPARTY_BG_REG_NUMBER_LENGTH_REGEX,
  INITIAL_IBAN_ITEM
} from 'utils/constants/constants';
import { initialFormValues } from 'utils/constants/initialValuesCreateCompany';
import { VAT_NUMBER_PREFIX_BULGARIA } from 'utils/constants/invoices';
import { Scopes } from 'utils/enums/Scopes';
import { uniqueIbanTest } from 'utils/helpers/ibanHelper';
import { AppRoutesEnum } from 'utils/routes';

import { fullFieldWidth, submitButton } from 'styles/components/Common';

import { companyAtom } from 'state/state';

interface CreateUpdateCompanyFormProps {
  companyDetails?: Company;
  onDelete: () => void;
}

export const CreateUpdateCompanyForm = ({
  companyDetails,
  onDelete
}: CreateUpdateCompanyFormProps) => {
  const { companyId } = useParams();

  const [company, setCompany] = useAtom(companyAtom);

  const { editCompany, addCompany } = useCompanyController();
  const navigate = useNavigate();
  const { translate } = useTranslations();
  const { updateCompaniesList } = useCompanies();
  const [isEdit, setIsEdit] = useState(!companyId);
  const { checkPermission } = usePermissions();

  const {
    isOpen: isDeleteModalOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal
  } = useModal();

  const initialValues = useMemo(() => {
    if (companyId && companyDetails) {
      return {
        ...initialFormValues,
        name: companyDetails.name || '',
        alias: companyDetails.alias || '',
        registrationNumber: companyDetails.registrationNumber || '',
        companyIbanList: companyDetails.companyIbanList.length
          ? companyDetails.companyIbanList
          : [{ ...INITIAL_IBAN_ITEM }],
        vatNumber: companyDetails.vatNumber || ''
      };
    }
    return {
      ...initialFormValues,
      companyIbanList: [{ ...INITIAL_IBAN_ITEM }]
    };
  }, [companyId, companyDetails]);

  const onSubmit = async (values: Company, currentCompany: Company) => {
    // Removing all the empty iban properties
    const filteredArray = values.companyIbanList?.filter(
      (item) => item.iban !== ''
    );

    const requestBody = {
      ...values,
      ...(companyId && { id: currentCompany.id }),
      companyIbanList: filteredArray,
      name: values.name.trim(),
      alias: values.alias.trim(),
      registrationNumber: values.registrationNumber.trim(),
      vatNumber: values.vatNumber?.trim()
    };

    const response = companyId
      ? await editCompany(currentCompany.id as number, requestBody)
      : await addCompany(requestBody);

    setCompany(response);

    updateCompaniesList(response);
    if (companyId) {
      setIsEdit(false);
    } else {
      navigate(
        AppRoutesEnum.COMPANY_DETAILS.replace(
          COMPANY_PARAMETER,
          response.id.toString()
        )
      );
    }
  };

  const canDeleteCompany = useMemo(
    () => checkPermission(ResourceTypes.COMPANIES, [Scopes.DELETE]),
    [checkPermission]
  );

  const handleEditClick = () => {
    setIsEdit(true);
  };

  const handleCancelClick = (resetForm: () => void) => {
    setIsEdit(false);
    resetForm();
  };

  const schema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().trim().required(translate('errors.companyName')),
        registrationNumber: Yup.string()
          .trim()
          .when('vatNumber', (vatNumber, innerSchema) => {
            const vatNumberStr = String(vatNumber[0] || '');
            return vatNumberStr?.startsWith(VAT_NUMBER_PREFIX_BULGARIA)
              ? innerSchema
                  .required(translate('errors.counterpartyRegistrationNumber'))
                  .matches(
                    COUNTERPARTY_BG_REG_NUMBER_LENGTH_REGEX,
                    translate('errors.counterpartyRegistrationNumberBG')
                  )
              : innerSchema.required(
                  translate('errors.counterpartyRegistrationNumber')
                );
          }),
        vatNumber: Yup.string().trim().required(translate('errors.vatNumber')),
        alias: Yup.string().trim().required(translate('errors.companyAlias')),
        companyIbanList: Yup.array()
          .of(
            Yup.object().shape({
              iban: Yup.string().trim()
            })
          )
          .test({
            name: 'unique',
            test(value, context) {
              return uniqueIbanTest(
                value as Iban[] | undefined,
                context,
                translate
              );
            }
          })
      }),
    [translate]
  );

  return (
    <Box>
      <Formik
        onSubmit={(values) =>
          onSubmit(values as Company, companyDetails as Company)
        }
        initialValues={initialValues}
        validationSchema={schema}
        enableReinitialize
      >
        {({ values, touched, errors, resetForm }) => {
          return (
            <Form
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px'
              }}
            >
              <Box sx={fullFieldWidth} height={75}>
                <Field
                  as={TextField}
                  label={translate('labels.name')}
                  name="name"
                  fullWidth
                  placeholder={translate('labels.name')}
                  required
                  disabled={!isEdit}
                  error={!!touched.name && !!errors.name}
                  helperText={<ErrorMessage name="name" />}
                />
              </Box>
              <Box sx={fullFieldWidth} height={75}>
                <Field
                  as={TextField}
                  label={translate('labels.companyRegistrationNumber')}
                  name="registrationNumber"
                  placeholder={translate('labels.companyRegistrationNumber')}
                  fullWidth
                  required
                  disabled={!isEdit}
                  error={
                    !!touched.registrationNumber && !!errors.registrationNumber
                  }
                  helperText={<ErrorMessage name="registrationNumber" />}
                />
              </Box>
              <Box sx={fullFieldWidth} height={75}>
                <Field
                  as={TextField}
                  label={translate('labels.vatNumber')}
                  name="vatNumber"
                  placeholder={translate('labels.vatNumber')}
                  fullWidth
                  required
                  disabled={!isEdit}
                  error={!!touched.vatNumber && !!errors.vatNumber}
                  helperText={<ErrorMessage name="vatNumber" />}
                />
              </Box>
              <Box sx={fullFieldWidth} height={75}>
                <Field
                  as={TextField}
                  label={translate('labels.shortName')}
                  name="alias"
                  placeholder={translate('labels.shortName')}
                  disabled={!isEdit}
                  fullWidth
                  required
                  error={!!touched.alias && !!errors.alias}
                  helperText={<ErrorMessage name="alias" />}
                />
              </Box>
              <CustomFormikFieldArray<CompanyIban>
                values={values.companyIbanList}
                initialItem={INITIAL_IBAN_ITEM}
                isEdit={isEdit}
                name="companyIbanList"
              >
                {(index) => (
                  <Box sx={fullFieldWidth} height={85}>
                    <Field
                      as={TextField}
                      label={translate('labels.iban')}
                      name={`companyIbanList.${index}.iban`}
                      placeholder={translate('labels.iban')}
                      error={errors?.companyIbanList?.[index]}
                      disabled={!isEdit}
                      sx={fullFieldWidth}
                    />
                    {errors?.companyIbanList?.[index] && (
                      <FormHelperText error>
                        {errors.companyIbanList[index].toString()}
                      </FormHelperText>
                    )}
                  </Box>
                )}
              </CustomFormikFieldArray>

              <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                {isEdit ? (
                  <Button
                    variant="contained"
                    type="submit"
                    key="submit"
                    sx={{ ...submitButton, width: '45%' }}
                  >
                    {companyId
                      ? translate('buttons.save')
                      : translate('buttons.add')}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    key="edit"
                    sx={{ ...submitButton, width: '45%' }}
                    onClick={handleEditClick}
                  >
                    {translate('buttons.edit')}
                  </Button>
                )}
                {companyId && canDeleteCompany && (
                  <Button
                    variant="contained"
                    color="error"
                    sx={{ ...submitButton, width: '45%' }}
                    onClick={
                      isEdit
                        ? () => handleCancelClick(resetForm)
                        : openDeleteModal
                    }
                    type={isEdit ? 'reset' : 'button'}
                  >
                    {translate(isEdit ? 'buttons.cancel' : 'buttons.delete')}
                  </Button>
                )}
              </Box>
            </Form>
          );
        }}
      </Formik>
      <ConfirmationDialog
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={onDelete}
        title={translate('labels.deleteCompany')}
        showInputField
        confirmationString={companyDetails?.name}
        size="sm"
      >
        {translate('messages.deleteCompanyConfirmation')}
      </ConfirmationDialog>
    </Box>
  );
};
