import WarningIcon from '@mui/icons-material/Warning';
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import { yellow } from '@mui/material/colors';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Company, Invoice, InvoiceSection, DocumentType } from 'openapi';

import { useTranslations } from 'context/TranslationContext';

import { useFormHelpers } from 'hooks/useFormHelpers';

import { InvoiceSectionKey } from 'utils/interfaces/InvoiceProps';

import { arrayFieldFormContainer } from 'styles/components/Common';
import {
  verificationFormFieldsWrapper,
  verificationFormItem
} from 'styles/pages/InvoiceVerificationStyle';

import { ActionButtons } from './ActionButtons';

interface ReceiverFormProps {
  currentInvoice: Invoice;
  allCompanies: Company[];
  handleVerify: (values: Invoice, section: InvoiceSectionKey) => void;
}

export const ReceiverForm = ({
  currentInvoice,
  allCompanies,
  handleVerify
}: ReceiverFormProps) => {
  const { translate } = useTranslations();
  const { getOptionLabelReceiverName } = useFormHelpers();

  const { control, handleSubmit, getValues, watch, formState } =
    useForm<Invoice>({
      defaultValues: {
        companyId: currentInvoice.companyId,
        documentType: currentInvoice.documentType
          ? currentInvoice.documentType
          : DocumentType.INVOICE,
        isAlreadyPaid: currentInvoice.isAlreadyPaid
      }
    });

  const [isFormValid, setIsFormValid] = useState<boolean>(true);

  const handleVerifySection = () => {
    handleVerify(
      {
        companyId: getValues('companyId'),
        documentType: getValues('documentType'),
        isAlreadyPaid: getValues('isAlreadyPaid')
      },
      InvoiceSection.RECEIVER
    );
  };

  useEffect(() => {
    setIsFormValid(formState.isValid);
  }, [formState.isValid]);

  const watchedCompanyId = watch('companyId');

  const isReceiverWrong = useMemo(
    () =>
      currentInvoice.receiverRegistrationNumber !==
      allCompanies.find((company) => company.id === watchedCompanyId)
        ?.registrationNumber,
    [currentInvoice, watchedCompanyId, allCompanies]
  );

  return (
    <Box sx={{ pt: 2.5 }}>
      <form onSubmit={handleSubmit(handleVerifySection)}>
        <ActionButtons isFormValid={isFormValid} />
        <Box sx={verificationFormFieldsWrapper}>
          <Box
            sx={{
              ...verificationFormFieldsWrapper,
              minHeight: '80px',
              width: '50%',
              alignItems: 'baseline'
            }}
          >
            {allCompanies?.length > 0 && (
              <Box sx={{ ...arrayFieldFormContainer, flexDirection: 'column' }}>
                <Controller
                  name="companyId"
                  control={control}
                  render={({ field }) => (
                    <FormControl sx={verificationFormItem}>
                      <InputLabel>{translate('labels.receiver')}</InputLabel>
                      <Select
                        {...field}
                        sx={{ textAlign: 'left' }}
                        label={translate('labels.receiver')}
                      >
                        {allCompanies.map((company: Company) => (
                          <MenuItem key={company.id} value={company.id}>
                            {getOptionLabelReceiverName(company)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                {isReceiverWrong && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: 30,
                      marginRight: 1,
                      gap: 0.5
                    }}
                  >
                    <WarningIcon
                      sx={{
                        fontSize: 22,
                        color: yellow[900],
                        display: currentInvoice.isReceiverVerified
                          ? 'none'
                          : 'block'
                      }}
                    />
                    <Typography fontSize={10}>
                      {translate('messages.receiverIsNotConfirmed')}
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
          </Box>
          <Box width="50%" display="flex" flexDirection="column">
            <Controller
              name="documentType"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth sx={verificationFormItem}>
                  <InputLabel>{`${translate(
                    'labels.documentType'
                  )}*`}</InputLabel>
                  <Select
                    {...field}
                    sx={{ textAlign: 'left' }}
                    label={translate('labels.documentType')}
                  >
                    {Object.values(DocumentType).map((documentType) => (
                      <MenuItem key={documentType} value={documentType}>
                        {documentType}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Box>
        </Box>
        <Box display="flex">
          <Controller
            name="isAlreadyPaid"
            control={control}
            render={({ field }) => (
              <Box display="flex" alignItems="center">
                <Typography>{translate('labels.alreadyPaid')}</Typography>
                <Checkbox
                  size="medium"
                  defaultChecked={currentInvoice.isAlreadyPaid}
                  {...field}
                />
              </Box>
            )}
          />
        </Box>
      </form>
    </Box>
  );
};
