import { ChevronLeft, ChevronRight, ManageAccounts } from '@mui/icons-material';
import {
  Box,
  Button,
  createTheme,
  Link,
  List,
  ListItem,
  Stack,
  ThemeProvider
} from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import { useCompanyController } from 'api/controllers/CompanyController';

import { Company } from 'openapi';

import { CompanySelect } from 'components/Sidebar/CompanySelect';
import { createSidebarConditionalsManager } from 'components/Sidebar/SidebarHelper';

import { useCompanies } from 'context/CompanyContext';
import { useTranslations } from 'context/TranslationContext';

import { useRouteSecurity } from 'hooks/useRouteSecurity';

import { COMPANY_PARAMETER } from 'utils/constants/constants';
import { isAuthenticated } from 'utils/helpers/authHelper';
import { AppRoutesEnum } from 'utils/routes';

import { linkStyle, logo as logoStyle } from 'styles/components/HeaderStyle';
import {
  sidebarMainList,
  sidebarLightTheme,
  sidebarIconStyleSelected
} from 'styles/components/SidebarStyle';

import { SidebarTab } from './SidebarTab';
import { SidebarTabsList } from './SidebarTabsList';

interface SidebarProps {
  setSidebarExpanded: (value: boolean) => void;
  isSidebarExpanded: boolean;
}

export const Sidebar: React.FC<SidebarProps> = ({
  setSidebarExpanded,
  isSidebarExpanded
}) => {
  const location = useLocation();
  const { translate } = useTranslations();
  const { getAllCompanies } = useCompanyController();
  const { companiesList, setCompaniesList } = useCompanies();
  const { getHomePageRoute } = useRouteSecurity();
  const navigate = useNavigate();
  const { checkRoutePermission } = useRouteSecurity();

  const sidebarTheme = createTheme(sidebarLightTheme);

  const layoutConditionalsManager = useMemo(
    () => createSidebarConditionalsManager(isSidebarExpanded),
    [isSidebarExpanded]
  );

  const currentCompanyId = useMemo(() => {
    const companyId = location.pathname.split('/')[2];
    return Number.isNaN(Number(companyId)) ? '' : companyId;
  }, [location.pathname]);

  const isUserManagementPage = useMemo(() => {
    return (
      location.pathname === AppRoutesEnum.USER_MANAGEMENT ||
      location.pathname.replace(currentCompanyId, COMPANY_PARAMETER) ===
        AppRoutesEnum.COMPANY_USER_MANAGEMENT
    );
  }, [location]);

  const isUserManagementVisible = useMemo(
    () =>
      (checkRoutePermission(AppRoutesEnum.USER_MANAGEMENT) ||
        checkRoutePermission(AppRoutesEnum.COMPANY_USER_MANAGEMENT)) &&
      !!companiesList.length,
    [checkRoutePermission, companiesList]
  );

  const navigateUserManagement = useCallback(() => {
    navigate(
      currentCompanyId
        ? AppRoutesEnum.COMPANY_USER_MANAGEMENT.replace(
            COMPANY_PARAMETER,
            currentCompanyId
          )
        : AppRoutesEnum.USER_MANAGEMENT
    );
  }, [navigate, currentCompanyId]);

  const fetchCompanies = useCallback(async () => {
    const allCompanies = await getAllCompanies();
    const sortedCompanies = allCompanies.sort(
      (a: Company, b: Company) => a.id! - b.id!
    );
    setCompaniesList([...sortedCompanies]);
  }, [setCompaniesList]);

  useEffect(() => {
    fetchCompanies();
  }, []);

  const handleSidebarResize = () => {
    setSidebarExpanded(!isSidebarExpanded);
  };

  return (
    <ThemeProvider theme={sidebarTheme}>
      {isAuthenticated() && (
        <Box sx={layoutConditionalsManager.sidebarStyle}>
          <Button
            sx={layoutConditionalsManager.expandButtonStyle}
            onClick={handleSidebarResize}
          >
            {isSidebarExpanded ? <ChevronLeft /> : <ChevronRight />}
          </Button>
          <List sx={sidebarMainList}>
            <ListItem sx={logoStyle}>
              <Link component={NavLink} to={getHomePageRoute()} sx={linkStyle}>
                {translate('labels.iHub')}
              </Link>
            </ListItem>

            <CompanySelect
              companiesList={companiesList}
              isSidebarExpanded={isSidebarExpanded}
              currentCompanyId={currentCompanyId}
            />

            {location.pathname !== AppRoutesEnum.ADD_COMPANY && (
              <SidebarTabsList
                currentCompanyId={currentCompanyId}
                isSidebarExpanded={isSidebarExpanded}
              />
            )}
          </List>
          {isUserManagementVisible && (
            <Stack sx={{ flex: 1, justifyContent: 'end' }}>
              <Box>
                <SidebarTab
                  isSidebarExpanded={isSidebarExpanded}
                  isSelected={isUserManagementPage}
                  onClick={navigateUserManagement}
                  text={translate('labels.userManagement')}
                  tabIcon={
                    <ManageAccounts
                      sx={isUserManagementPage ? sidebarIconStyleSelected : {}}
                    />
                  }
                />
              </Box>
            </Stack>
          )}
        </Box>
      )}
    </ThemeProvider>
  );
};

export default Sidebar;
