import { Box, Paper, Typography, Chip, Divider } from '@mui/material';

import { Invoice } from 'openapi';

import { ApprovalButtons } from 'components/forms/InvoiceVerificationForms/ApprovalButtons';
import { LabelValueField } from 'components/shared/LabelField/LabelValueField';

import { useTranslations } from 'context/TranslationContext';

interface ApprovalsProps {
  currentInvoice: Invoice;
  handleToggleApprove: () => void;
  handleBack?: () => void;
  handleToggleReject: () => void;
  handleReturnToApproved: () => void;
}

export const Approvals = ({
  currentInvoice,
  handleBack,
  handleToggleApprove,
  handleToggleReject,
  handleReturnToApproved
}: ApprovalsProps) => {
  const { translate } = useTranslations();

  const renderCounterpartyValue = (): string => {
    const {
      counterpartyName,
      counterpartyRegistrationNumber,
      counterpartyVatNumber
    } = currentInvoice;

    // Both registration number and VAT number are available
    if (counterpartyRegistrationNumber && counterpartyVatNumber) {
      return `${counterpartyName} (${counterpartyRegistrationNumber}, ${counterpartyVatNumber})`;
    }
    // Only registration number is available
    if (counterpartyRegistrationNumber) {
      return `${counterpartyName} (${counterpartyRegistrationNumber})`;
    }
    // Only VAT number is available
    if (counterpartyVatNumber) {
      return `${counterpartyName} (${counterpartyVatNumber})`;
    }
    // Neither registration number nor VAT number are available
    return counterpartyName as string;
  };

  return (
    <Box sx={{ width: '100%' }}>
      <ApprovalButtons
        handleToggleReject={handleToggleReject}
        handleBack={handleBack}
        handleToggleApprove={handleToggleApprove}
        handleReturnToApproved={handleReturnToApproved}
        currentInvoice={currentInvoice}
      />

      <Paper
        elevation={3}
        sx={{ padding: 2, backgroundColor: '#fefefe', marginTop: 2 }}
      >
        <Typography
          variant="h5"
          mb={2}
          sx={{ textAlign: 'center', fontWeight: 'bold' }}
        >
          {translate('labels.overview')}
        </Typography>

        {/* Receiver Information */}
        <LabelValueField
          label={translate('labels.receiver')}
          value={currentInvoice.shortNameCompany || '-'}
        />

        <LabelValueField
          label={translate('labels.counterparty')}
          value={renderCounterpartyValue()}
        />

        <LabelValueField
          label={translate('labels.documentType')}
          value={currentInvoice.documentType || '-'}
        >
          {currentInvoice.isAlreadyPaid && (
            <Chip
              color="success"
              label={translate('labels.paid')}
              sx={{
                height: 18,
                fontSize: '0.75rem'
              }}
            />
          )}
        </LabelValueField>

        <Divider sx={{ my: 2 }} />
        {/* Document and Payment Status */}

        <LabelValueField
          label={translate('labels.invoiceNumberAndDate')}
          value={`${currentInvoice.invoiceNumber || '-'} / ${
            currentInvoice.invoiceDate
          }`}
        />

        {currentInvoice.dueDate && (
          <LabelValueField
            label={translate('labels.dueDate')}
            value={currentInvoice.dueDate || '-'}
          />
        )}

        <LabelValueField
          label={translate('labels.expenseType')}
          value={currentInvoice.expenseType?.name || '-'}
        />

        <Divider sx={{ my: 2 }} />

        {/* Financial Details */}
        {currentInvoice.vatBase !== null &&
          currentInvoice.vatBase !== undefined && (
            <LabelValueField
              label={translate('labels.vatBase')}
              value={`${currentInvoice.vatBase?.toFixed(2)} ${
                currentInvoice.currency
              }`}
            />
          )}

        {currentInvoice.vatAmount !== null &&
          currentInvoice.vatAmount !== undefined && (
            <LabelValueField
              label={translate('labels.vatAmount')}
              value={`${currentInvoice.vatAmount?.toFixed(2)} ${
                currentInvoice.currency
              }`}
            />
          )}

        <LabelValueField
          label={translate('labels.invoiceAmount')}
          value={`${currentInvoice.invoiceAmount?.toFixed(2)} ${
            currentInvoice.currency
          }`}
        />
      </Paper>
    </Box>
  );
};
