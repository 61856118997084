/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum InvoiceStages {
    UPLOADED = 'UPLOADED',
    DRAFT = 'DRAFT',
    VALIDATED = 'VALIDATED',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    PAID = 'PAID',
}
