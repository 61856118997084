import { ResourceTypes } from 'openapi';

import { Scopes } from 'utils/enums/Scopes';
import { AtLeastOneProperty } from 'utils/interfaces/AtLeastOneProperty';
import { AppRoutesEnum } from 'utils/routes';

type ResourcePermissions = {
  [resourceKey in ResourceTypes]?: AtLeastOneProperty<{
    [permissionKey in Scopes]: boolean;
  }>;
};

export type RoutePermissionsType = {
  [key in AppRoutesEnum]?: ResourcePermissions;
};

export const ROUTE_PERMISSIONS: RoutePermissionsType = {
  [AppRoutesEnum.HOME]: {},
  [AppRoutesEnum.NOT_FOUND]: {},
  [AppRoutesEnum.INVOICES]: {},
  [AppRoutesEnum.INVOICES_APPROVAL]: {
    [ResourceTypes.INVOICE_APPROVAL]: {
      [Scopes.READ]: true
    }
  },
  [AppRoutesEnum.INVOICES_ACCOUNTING]: {
    [ResourceTypes.INVOICE_ACCOUNTING]: {
      [Scopes.READ]: true
    }
  },
  [AppRoutesEnum.INVOICES_PAYMENT]: {
    [ResourceTypes.INVOICE_PAYMENTS]: {
      [Scopes.READ]: true
    }
  },
  [AppRoutesEnum.COMPANY_INVOICES]: {},
  [AppRoutesEnum.COMPANY_INVOICES_APPROVAL]: {
    [ResourceTypes.INVOICE_APPROVAL]: {
      [Scopes.READ]: true
    }
  },
  [AppRoutesEnum.COMPANY_INVOICES_ACCOUNTING]: {
    [ResourceTypes.INVOICE_ACCOUNTING]: {
      [Scopes.READ]: true
    }
  },
  [AppRoutesEnum.COMPANY_INVOICES_PAYMENT]: {
    [ResourceTypes.INVOICE_PAYMENTS]: {
      [Scopes.READ]: true
    }
  },
  [AppRoutesEnum.INVOICE_VERIFICATION]: {},
  [AppRoutesEnum.ADD_COMPANY]: {
    [ResourceTypes.COMPANIES]: {
      [Scopes.CREATE]: true,
      [Scopes.READ]: true
    }
  },
  [AppRoutesEnum.COMPANY_DETAILS]: {
    [ResourceTypes.COMPANIES]: {
      [Scopes.READ]: true,
      [Scopes.UPDATE]: true
    }
  },
  [AppRoutesEnum.COMPANY_DASHBOARD]: {
    [ResourceTypes.DASHBOARD]: {
      [Scopes.READ]: true
    }
  },
  [AppRoutesEnum.DASHBOARD]: {
    [ResourceTypes.DASHBOARD]: {
      [Scopes.READ]: true
    }
  },
  [AppRoutesEnum.COMPANY_COUNTERPARTIES]: {
    [ResourceTypes.COUNTERPARTIES]: {
      [Scopes.READ]: true,
      [Scopes.UPDATE]: true,
      [Scopes.DELETE]: true,
      [Scopes.CREATE]: true
    }
  },
  [AppRoutesEnum.COMPANY_EXPENSE_TYPES]: {
    [ResourceTypes.EXPENSE_TYPES]: {
      [Scopes.READ]: true
    }
  },
  [AppRoutesEnum.COMPANY_BUDGETING]: {
    [ResourceTypes.BUDGETS]: {
      [Scopes.READ]: true,
      [Scopes.UPDATE]: true,
      [Scopes.DELETE]: true,
      [Scopes.CREATE]: true
    }
  },
  [AppRoutesEnum.COMPANY_PAYROLL]: {
    [ResourceTypes.PAYROLL]: {
      [Scopes.READ]: true,
      [Scopes.UPDATE]: true,
      [Scopes.DELETE]: true,
      [Scopes.CREATE]: true
    }
  },
  [AppRoutesEnum.USER_MANAGEMENT]: {
    [ResourceTypes.USERS]: {
      [Scopes.READ]: true,
      [Scopes.UPDATE]: true,
      [Scopes.DELETE]: true,
      [Scopes.CREATE]: true
    }
  },
  [AppRoutesEnum.COMPANY_USER_MANAGEMENT]: {
    [ResourceTypes.USERS]: {
      [Scopes.READ]: true,
      [Scopes.UPDATE]: true,
      [Scopes.DELETE]: true,
      [Scopes.CREATE]: true
    }
  },
  [AppRoutesEnum.COMPANY_APPROVALS]: {
    [ResourceTypes.EXPENSE_TYPES]: {
      [Scopes.READ]: true
    }
  }
};
