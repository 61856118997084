import {
  Handshake,
  RequestQuote,
  Receipt,
  Savings,
  Business,
  Approval,
  Checklist,
  Payment,
  AccountBalanceWallet
} from '@mui/icons-material';
import { Box } from '@mui/material';
import { useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import SidebarIcon from 'components/Sidebar/SidebarIcon/SidebarIcon';
import { SidebarTab } from 'components/Sidebar/SidebarTab';

import { useTranslations } from 'context/TranslationContext';

import { useRouteSecurity } from 'hooks/useRouteSecurity';

import { AppRoutesEnum } from 'utils/routes';

import {
  sidebarIconStyle,
  sidebarIconStyleSelected,
  sidebarIconStyleSmall
} from 'styles/components/SidebarStyle';

import dashboardIcon from 'assets/images/ic_dashboard.svg';
import invoicesIcon from 'assets/images/ic_invoice.svg';

import { getSidebarTabs, isPathSelected } from './SidebarHelper';

interface SidebarTabsListProps {
  currentCompanyId: string;
  isSidebarExpanded: boolean;
}

const tabIcons = {
  [AppRoutesEnum.DASHBOARD as string]: (isSelected: boolean) => (
    <SidebarIcon iconSource={dashboardIcon} shouldBeSelected={isSelected} />
  ),
  [AppRoutesEnum.INVOICES]: (isSelected: boolean) => (
    <SidebarIcon iconSource={invoicesIcon} shouldBeSelected={isSelected} />
  ),
  [AppRoutesEnum.INVOICES_ACCOUNTING]: (isSelected: boolean) => (
    <AccountBalanceWallet
      sx={{
        ...sidebarIconStyleSmall,
        ...(isSelected ? sidebarIconStyleSelected : {})
      }}
    />
  ),
  [AppRoutesEnum.INVOICES_APPROVAL]: (isSelected: boolean) => (
    <Checklist
      sx={{
        ...sidebarIconStyleSmall,
        ...(isSelected ? sidebarIconStyleSelected : {})
      }}
    />
  ),
  [AppRoutesEnum.INVOICES_PAYMENT]: (isSelected: boolean) => (
    <Payment
      sx={{
        ...sidebarIconStyleSmall,
        ...(isSelected ? sidebarIconStyleSelected : {})
      }}
    />
  ),
  [AppRoutesEnum.COMPANY_COUNTERPARTIES]: (isSelected: boolean) => (
    <Handshake
      sx={{
        ...sidebarIconStyle,
        ...(isSelected ? sidebarIconStyleSelected : {})
      }}
    />
  ),
  [AppRoutesEnum.COMPANY_PAYROLL]: (isSelected: boolean) => (
    <RequestQuote
      sx={{
        ...sidebarIconStyle,
        ...(isSelected ? sidebarIconStyleSelected : {})
      }}
    />
  ),
  [AppRoutesEnum.COMPANY_EXPENSE_TYPES]: (isSelected: boolean) => (
    <Receipt
      sx={{
        ...sidebarIconStyle,
        ...(isSelected ? sidebarIconStyleSelected : {})
      }}
    />
  ),
  [AppRoutesEnum.COMPANY_APPROVALS]: (isSelected: boolean) => (
    <Approval
      sx={{
        ...sidebarIconStyle,
        ...(isSelected ? sidebarIconStyleSelected : {})
      }}
    />
  ),
  [AppRoutesEnum.COMPANY_BUDGETING]: (isSelected: boolean) => (
    <Savings
      sx={{
        ...sidebarIconStyle,
        ...(isSelected ? sidebarIconStyleSelected : {})
      }}
    />
  ),
  [AppRoutesEnum.COMPANY_DETAILS]: (isSelected: boolean) => (
    <Business
      sx={{
        ...sidebarIconStyle,
        ...(isSelected ? sidebarIconStyleSelected : {})
      }}
    />
  )
};

export const SidebarTabsList = ({
  currentCompanyId,
  isSidebarExpanded
}: SidebarTabsListProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { translate } = useTranslations();
  const { checkRoutePermission } = useRouteSecurity();

  const sidebarTabs = useMemo(() => {
    return getSidebarTabs({
      currentCompanyId,
      translate,
      navigate,
      currentLocation: location.pathname,
      checkRoutePermission
    }).map((tab) => ({ ...tab, tabIcon: tabIcons[tab.route](tab.isSelected) }));
  }, [
    currentCompanyId,
    translate,
    isPathSelected,
    location.pathname,
    currentCompanyId,
    checkRoutePermission
  ]);

  return (
    <Box>
      {sidebarTabs.map((tab) => (
        <SidebarTab
          key={tab.tabText}
          onClick={tab.onClick}
          text={tab.tabText}
          isSelected={tab.isSelected}
          tabIcon={tab.tabIcon}
          isSidebarExpanded={isSidebarExpanded}
          tabFilterType={tab.filterType}
          isNested={tab.isNested}
          tabLongText={tab.tabLongText}
        />
      ))}
    </Box>
  );
};
