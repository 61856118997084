import { useCallback } from 'react';

import { ExportService } from 'openapi';

import { useInterceptor } from 'hooks/useInterceptor';

import { ExportForPayment } from '../../openapi/models/ExportForPayment';

export const useExportController = () => {
  const { interceptRequest } = useInterceptor();

  const { exportInvoicesForPayment, exportInvoicesZip } = ExportService;

  const exportInvoicesPayment = useCallback(
    (id: number, body: ExportForPayment) =>
      interceptRequest(exportInvoicesForPayment, {}, id, body),
    [exportInvoicesForPayment]
  );

  const exportInvoicesZipByIds = useCallback(
    (ids: number[]) => interceptRequest(exportInvoicesZip, {}, { ids }),
    [exportInvoicesZip]
  );

  return {
    exportInvoicesPayment,
    exportInvoicesZipByIds
  };
};
