import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography
} from '@mui/material';
import { useAtom } from 'jotai';
import { useEffect } from 'react';

import { FilterType } from 'utils/enums/FilterType';

import {
  selectedTabStyles,
  tabStyle,
  icon,
  tabTextRemoved,
  tabText
} from 'styles/components/SidebarStyle';

import { filterTypeAtom } from 'state/state';

interface SidebarTabProps {
  onClick: () => void;
  isSelected: boolean;
  text: string;
  isSidebarExpanded: boolean;
  tabIcon: React.ReactElement;
  tabFilterType?: FilterType;
  tabLongText?: string;
  isNested?: boolean;
}

export const SidebarTab = ({
  onClick,
  isSelected,
  text,
  isSidebarExpanded,
  tabIcon,
  tabFilterType,
  tabLongText,
  isNested
}: SidebarTabProps) => {
  const [filterType, setFilterType] = useAtom(filterTypeAtom);

  const handleClick = () => {
    if (tabFilterType && tabFilterType !== filterType) {
      setFilterType(tabFilterType);
    }

    onClick();
  };

  useEffect(() => {
    if (isSelected && tabFilterType && tabFilterType !== filterType) {
      setFilterType(tabFilterType);
    }
  }, []);

  const nestedStylesButton =
    isNested && isSidebarExpanded ? { padding: '0 0 0 3rem' } : {};
  const title = !isSidebarExpanded ? tabLongText || text : '';

  return (
    <Tooltip title={title} placement="right">
      <ListItemButton
        onClick={handleClick}
        sx={{
          ...(isSelected ? selectedTabStyles : tabStyle),
          ...nestedStylesButton
        }}
      >
        <ListItemIcon sx={icon}>{tabIcon}</ListItemIcon>

        <ListItemText>
          <Typography
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              fontSize: isNested ? '0.9rem' : '1rem',
              ...(!isSidebarExpanded ? tabTextRemoved : tabText)
            }}
          >
            {text}
          </Typography>
        </ListItemText>
      </ListItemButton>
    </Tooltip>
  );
};
