import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  ReactNode,
  useMemo,
  useRef
} from 'react';

import { TokenExpirationModal } from 'components/shared/Modal/TokenExpirationModal';

import { TOKEN_EXPIRY_WARNING_TIME_SECONDS } from 'utils/constants/constants';

import { viravaDefault } from 'config/authConfig';

interface TokenExpirationModalContextType {
  isModalVisible: boolean;
  handleLogout: () => Promise<void>;
  handleRefresh: () => Promise<void>;
}

export const TokenExpirationModalContext =
  createContext<TokenExpirationModalContextType>(
    {} as TokenExpirationModalContextType
  );

interface TokenExpirationModalProviderProps {
  children: ReactNode;
}

export const TokenExpirationModalProvider = ({
  children
}: TokenExpirationModalProviderProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const timeoutIdRef = useRef<number | undefined>(undefined);
  const refreshExpTime = useRef<number | undefined>();

  const clearExistingTimeout = useCallback(() => {
    if (timeoutIdRef.current !== undefined) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = undefined;
    }
  }, []);

  const setTokenExpiryWarning = useCallback(() => {
    clearExistingTimeout();
    const remainingTime =
      (viravaDefault.getSecondsUntilTokenExpiration() -
        TOKEN_EXPIRY_WARNING_TIME_SECONDS) *
      1000;

    refreshExpTime.current =
      new Date().getTime() +
      viravaDefault.getSecondsUntilTokenExpiration() * 1000;

    if (remainingTime > 0) {
      timeoutIdRef.current = window.setTimeout(() => {
        setIsModalVisible(true);
      }, remainingTime);
    } else {
      setIsModalVisible(true);
    }
  }, [clearExistingTimeout]);

  useEffect(() => {
    setTokenExpiryWarning();

    return clearExistingTimeout;
  }, [setTokenExpiryWarning, clearExistingTimeout]);

  const handleLogout = useCallback(async () => {
    clearExistingTimeout();
    await viravaDefault.logout(window.origin);
  }, [clearExistingTimeout]);

  const handleRefresh = useCallback(async () => {
    await viravaDefault.updateToken();
    setIsModalVisible(false);
    setTokenExpiryWarning();
  }, [setTokenExpiryWarning]);

  const contextValue = useMemo(
    () => ({ isModalVisible, handleLogout, handleRefresh }),
    [isModalVisible, handleLogout, handleRefresh]
  );

  return (
    <TokenExpirationModalContext.Provider value={contextValue}>
      {children}
      {isModalVisible && (
        <TokenExpirationModal
          onLogout={handleLogout}
          onRefresh={handleRefresh}
          isOpen={isModalVisible}
          refreshExpTime={refreshExpTime.current!}
        />
      )}
    </TokenExpirationModalContext.Provider>
  );
};
