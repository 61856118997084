export enum InvoiceSubmit {
  'Save' = 'save',
  'SaveAndNext' = 'saveAndNext'
}

export enum InvoiceVerificationMapper {
  isReceiverVerified = 'isSupplierVerified',
  isSupplierVerified = 'areIbansVerified',
  areIbansVerified = 'isInvoiceDataVerified',
  isInvoiceDataVerified = 'areProductsVerified',
  areProductsVerified = ''
}

export enum SectionIndex {
  SUPPLIER = 1,
  IBAN_LIST = 2,
  INVOICE_DATA = 3,
  PRODUCTS = 4,
  STATUS = 5
}

export enum InvoiceEventType {
  HEARTBEAT = 'heartbeat',
  INVOICE_CREATE = 'invoice_create',
  INVOICE_UPDATE = 'invoice_update',
  INVOICE_DELETE = 'invoice_delete',
  INVOICE_DUPLICATE = 'invoice_duplicate'
}

export enum ActiveStep {
  RECEIVER = 0,
  SUPPLIER = 1,
  INVOICE_DATA = 2,
  APPROVALS = 3,
  APPROVED = 4,
  PAID = 5,
  REJECTED = 6
}
