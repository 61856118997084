import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { Paper, Tooltip } from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridCellParams
} from '@mui/x-data-grid';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useAmountsController } from 'api/controllers/AmountsController';
import { useUserController } from 'api/controllers/UserController';

import { Amount, AmountCreate, ResourceTypes, User } from 'openapi';

import { CreateUpdateAmount } from 'components/forms/CreateUpdateAmount';
import { GridToolbar } from 'components/shared/GridToolbar/GridToolbar';
import { ConfirmationDialog } from 'components/shared/Modal/ConfirmationDialog';
import { Modal } from 'components/shared/Modal/Modal';

import { usePermissions } from 'context/PermissionsContext';
import { useTranslations } from 'context/TranslationContext';

import { useModal } from 'hooks/useModal';

import { ACTIONS, CHECK } from 'utils/constants/constants';
import { Scopes } from 'utils/enums/Scopes';
import { getAmountApprovalsColumns } from 'utils/helpers/approvalsHelpers';

import { commonDataGridContainerStyle } from 'styles/components/DataGridStyle';
import { invoicesDataGrid } from 'styles/components/InvoicesDataGridStyle';

export const AmountsApproval = () => {
  const { companyId } = useParams();

  const { translate } = useTranslations();
  const { checkPermission } = usePermissions();

  const { getAmounts, createAmountForCompany, deleteAmounts, updateAmount } =
    useAmountsController();
  const { getApprovers } = useUserController();

  const [amounts, setAmounts] = useState<Amount[]>([]);
  const [approvers, setApprovers] = useState<User[]>([]);

  const [amountForEdit, setAmountForEdit] = useState<Amount>();
  const [amountForDelete, setAmountForDelete] = useState<number>();

  // Modals
  const {
    openModal: openEditModal,
    closeModal: closeEditModal,
    isOpen: isEditModalOpen
  } = useModal();

  const {
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
    isOpen: isDeleteModalOpen
  } = useModal();

  const handleAddClick = () => {
    setAmountForEdit(undefined);
    openEditModal();
  };

  const handleEditClick = (params: GridCellParams) => {
    if (params.field !== CHECK && params.field !== ACTIONS) {
      setAmountForEdit(params.row);
      openEditModal();
    }
  };

  const handleCloseEditModal = () => {
    setAmountForEdit(undefined);
    closeEditModal();
  };

  const handleDeleteModal = (rowId: number) => {
    setAmountForDelete(rowId);
    openDeleteModal();
  };

  const handleCloseDeleteModal = () => {
    setAmountForDelete(undefined);
    closeDeleteModal();
  };

  const fetchAmounts = useCallback(async () => {
    const result = await getAmounts(Number(companyId));
    setAmounts(result.reverse());
  }, [getAmounts]);

  const fetchApprovers = useCallback(async () => {
    const result = await getApprovers(Number(companyId));
    setApprovers(result);
  }, []);

  useEffect(() => {
    fetchAmounts();
    fetchApprovers();
  }, [fetchAmounts]);

  const deleteAmount = useCallback(async () => {
    await deleteAmounts(Number(companyId), [amountForDelete as number]);
    handleCloseDeleteModal();

    fetchAmounts();
  }, [amountForDelete, companyId]);

  const handleSaveAmount = useCallback(
    async (values: AmountCreate) => {
      if (amountForEdit) {
        await updateAmount(Number(companyId), amountForEdit.id, values);
      } else {
        await createAmountForCompany(Number(companyId), values);
      }
      handleCloseEditModal();

      fetchAmounts();
    },
    [amountForEdit, companyId]
  );

  const columns = useMemo(
    () =>
      getAmountApprovalsColumns(translate)
        .map((column) => {
          switch (column.field) {
            case ACTIONS:
              return checkPermission(ResourceTypes.EXPENSE_TYPES, [
                Scopes.DELETE
              ])
                ? {
                    ...column,
                    renderCell: ({ row }: any) => (
                      <GridActionsCellItem
                        icon={
                          <DeleteTwoToneIcon
                            sx={(theme) => ({
                              color: theme.palette.error.main,
                              fontSize: '1.5rem'
                            })}
                            titleAccess={translate('labels.delete')}
                          />
                        }
                        label="Delete"
                        onClick={() => handleDeleteModal(row.id)}
                      />
                    )
                  }
                : null;
            default:
              return column;
          }
        })
        .filter((col) => col) as GridColDef[],
    [translate]
  );

  const editModalTitle = useMemo(
    () =>
      amountForEdit
        ? translate('approvals.editAmount')
        : translate('approvals.addAmount'),
    [amountForEdit, translate]
  );

  const allAmounts = amounts.map((amount) => amount.value);

  return (
    <Paper elevation={4} sx={commonDataGridContainerStyle}>
      <DataGrid
        rows={amounts}
        columns={columns}
        disableRowSelectionOnClick
        onCellClick={handleEditClick}
        sx={invoicesDataGrid}
        pageSizeOptions={[]}
        slots={{
          toolbar: GridToolbar
        }}
        slotProps={{
          toolbar: {
            handleAddClick,
            entries: amounts,
            isDefaultToolbarHidden: true
          }
        }}
        localeText={{
          noRowsLabel: translate('labels.noData')
        }}
      />
      <Modal
        isOpen={isEditModalOpen}
        hide={handleCloseEditModal}
        headerTitle={editModalTitle}
        maxWidth="sm"
      >
        <CreateUpdateAmount
          onSubmit={handleSaveAmount}
          approvers={approvers}
          initialValues={amountForEdit}
          allAmounts={allAmounts}
        />
      </Modal>

      <ConfirmationDialog
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={deleteAmount}
        title={translate('labels.deleteAmount')}
        size="sm"
      >
        {translate('messages.deleteAmount')}
      </ConfirmationDialog>
    </Paper>
  );
};
