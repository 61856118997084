import { Typography, Button, Grid } from '@mui/material';
import { useEffect, useRef, useState, useCallback } from 'react';

import { useTranslations } from 'context/TranslationContext';

import { TOKEN_EXPIRY_WARNING_TIME_SECONDS } from 'utils/constants/constants';

import { Modal } from './Modal';

interface TokenExpirationModalProps {
  onLogout: () => void;
  onRefresh: () => void;
  isOpen: boolean;
  refreshExpTime: number;
}

export const TokenExpirationModal = ({
  onLogout,
  onRefresh,
  isOpen,
  refreshExpTime
}: TokenExpirationModalProps) => {
  const { translate } = useTranslations();
  const [countdown, setCountdown] = useState(
    Math.floor((refreshExpTime - new Date().getTime()) / 1000)
  );
  const intervalId = useRef<NodeJS.Timeout | undefined>(undefined);

  const clearIntervalRef = useCallback(() => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
      intervalId.current = undefined;
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      setCountdown(TOKEN_EXPIRY_WARNING_TIME_SECONDS);
      intervalId.current = setInterval(() => {
        if (new Date().getTime() >= refreshExpTime) {
          clearIntervalRef();
          onLogout();
        } else {
          setCountdown(
            Math.floor((refreshExpTime - new Date().getTime()) / 1000)
          );
        }
      }, 1000);
    } else {
      clearIntervalRef();
    }

    return clearIntervalRef;
  }, [isOpen, onLogout, clearIntervalRef]);

  return (
    <Modal
      isOpen={isOpen}
      hide={onRefresh}
      headerTitle={translate('messages.sessionExpiringSoon')}
      maxWidth="sm"
    >
      <Typography>
        {translate('messages.sessionWillExpireIn', {
          time: countdown.toString()
        })}
      </Typography>
      <Grid container wrap="nowrap" gap={2} justifyContent="end" sx={{ mt: 3 }}>
        <Button variant="outlined" color="error" onClick={onLogout}>
          {translate('buttons.logout')}
        </Button>
        <Button variant="contained" color="primary" onClick={onRefresh}>
          {translate('buttons.refreshSession')}
        </Button>
      </Grid>
    </Modal>
  );
};
