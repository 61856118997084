export const appHeader = {
  height: '64px',
  display: 'flex',
  justifyContent: 'end'
};

export const headerWrapper = {
  display: 'flex',
  alignItems: 'center',
  padding: '0 24px'
};

export const logo = {
  maxWidth: '196px',
  height: '32px',
  margin: '0',
  padding: '30px 0px 20px 25px'
};

export const linkStyle = {
  textDecoration: 'none',
  fontSize: 32
};
