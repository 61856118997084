export enum HttpStatusCodes {
  Success = 200,
  Created = 201,
  Accepted = 202,
  NoContent = 204,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  Conflict = 409,
  PayloadTooLarge = 413,
  ExpectationFailed = 417,
  InternalServerError = 500,
  ServerUnavailable = 503
}
