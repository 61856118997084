import { GridRenderCellParams } from '@mui/x-data-grid';

import { User } from 'openapi';

import { ListHoverGridCell } from 'components/shared/gridCells/ListHoverGridCell';

import {
  COLUMNS_DEFAULT_OPTIONS,
  ACTIONS,
  AMOUNT_MAX,
  AMOUNT_MIN
} from 'utils/constants/constants';
import { formatNumberWithSpaces, formatMoney } from 'utils/helpers/moneyHelper';
import { TranslateFunction } from 'utils/interfaces/Translations';

export const getAmountApprovalsColumns = (translate: TranslateFunction) => {
  return [
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'value',
      headerName: translate('labels.amount'),
      minWidth: 150,
      maxWidth: 200,
      renderCell: ({ value }: { value: number }) => (
        <>{formatNumberWithSpaces(value)}</>
      )
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'approvers',
      headerName: translate('labels.approvers'),
      sortable: false,
      renderCell: ({ row }: GridRenderCellParams) => {
        const approversList = row.approvers.map(
          (approver: User) => approver.name
        );
        return <ListHoverGridCell list={approversList} />;
      }
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: ACTIONS,
      headerName: '',
      minWidth: 50,
      maxWidth: 50,
      align: 'right',
      headerAlign: 'right',
      sortable: false
    }
  ];
};

export const getCounterpartiesApprovalsColumns = (
  translate: TranslateFunction
) => {
  return [
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'name',
      headerName: translate('labels.name'),
      minWidth: 250,
      maxWidth: 300
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'approvers',
      headerName: translate('labels.approvers'),
      sortable: false,
      renderCell: ({ row }: GridRenderCellParams) => {
        const approversList = row.approvers.map(
          (approver: User) => approver.name
        );
        return <ListHoverGridCell list={approversList} />;
      }
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'approvalThreshold',
      headerName: translate('labels.autoApprovalThreshold'),
      flex: 0.5,
      valueFormatter: (params: any) =>
        params.value
          ? formatMoney(params.value as number)
          : translate('labels.disabled'),
      minWidth: 120,
      maxWidth: 150
    }
  ];
};

export const validateAmount = (value: number | undefined) => {
  if (
    value === undefined ||
    value === null ||
    value === 0 ||
    value.toString() === '0' ||
    value.toString() === ''
  ) {
    return true;
  }

  const isInRange = value >= AMOUNT_MIN && value <= AMOUNT_MAX;

  return isInRange || 'error';
};
