import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Autocomplete,
  FormHelperText,
  InputAdornment,
  CircularProgress,
  IconButton,
  Tooltip
} from '@mui/material';
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import { useAdditionalFieldsController } from 'api/controllers/AdditionalFieldsController';

import {
  Counterparty,
  CounterpartyCreateUpdate,
  CounterpartyDropDown,
  ExpenseType,
  Iban
} from 'openapi';

import { VerifyCounterPartyIbanButton } from 'components/shared/buttons/VerifyCounterPartyIbanButton/VeryfyCounterPartyIbanButton';

import { useTranslations } from 'context/TranslationContext';

import { useDebounce } from 'hooks/useDebounce';

import {
  INITIAL_IBAN_ITEM,
  COUNTERPARTY_IBAN_ADDITIONAL_INITIAL_FIELDS
} from 'utils/constants/constants';
import { COUNTERPARTY_SEARCH_MIN_CHARACTERS } from 'utils/constants/invoices';
import { uniqueIbanValidation } from 'utils/helpers/ibanHelper';
import {
  getCounterPartySearchOptionLabel,
  validateIban,
  validateRegistrationNumber,
  validateVatNumber
} from 'utils/helpers/invoiceHelpers';
import { mapCounterPartyValues } from 'utils/mappers/counterParty';

import {
  submitButton,
  arrayFieldFormContainer
} from 'styles/components/Common';
import {
  verificationFormFieldsWrapper,
  verificationFormItem,
  smallVerificationFieldWidth,
  verificationFormFields
} from 'styles/pages/InvoiceVerificationStyle';

interface CreateUpdateCounterPartyProps {
  readonly handleSubmit: (values: CounterpartyCreateUpdate) => void;
  readonly initialValues?: Counterparty;
  readonly expenseTypeOptions: ExpenseType[];
  readonly counterpartyOptions: CounterpartyDropDown[];
}

export const CreateUpdateCounterParty = ({
  initialValues,
  handleSubmit: handleFormSubmit,
  expenseTypeOptions
}: CreateUpdateCounterPartyProps) => {
  const { translate } = useTranslations();
  const { getDropdownCounterparties } = useAdditionalFieldsController();

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<CounterpartyDropDown[]>([]);
  const [searchParams, setSearchParams] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCounterPartyId, setSelectedCounterPartyId] = useState<
    number | null
  >(null);

  const notEnoughCharactersToSearch = useMemo(() => {
    return (searchParams || '').length < COUNTERPARTY_SEARCH_MIN_CHARACTERS;
  }, [searchParams]);

  const getCounterParties = useCallback(async () => {
    if (notEnoughCharactersToSearch) {
      setIsLoading(false);
      setOptions([]);
      return;
    }
    const result = await getDropdownCounterparties(searchParams);
    setOptions(result);
    setIsLoading(false);
  }, [getDropdownCounterparties, searchParams, setOptions]);

  const debouncedSearch = useDebounce(getCounterParties, 500);

  useEffect(() => {
    setIsLoading(true);
    debouncedSearch();
  }, [searchParams, debouncedSearch]);

  const {
    control,
    formState,
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
    trigger,
    setError
  } = useForm<CounterpartyCreateUpdate>({
    mode: 'onChange',
    defaultValues: mapCounterPartyValues(initialValues)
  });

  const [isFormValid, setIsFormValid] = useState<boolean>(true);

  const { append, remove } = useFieldArray({
    control,
    name: 'ibanList',
    keyName: 'fieldId'
  });

  const watchedCounterpartyRegistrationNumber = watch('registrationNumber');
  const watchedCounterpartyVatNumber = watch('vatNumber');
  const watchedWithoutRegistrationNumber = watch('withoutRegistrationNumber');
  const watchedIbanList = watch('ibanList');

  // Validation logic for the registration number and vat number
  const isCounterpartyRegistrationNumberValid = useMemo(
    () =>
      validateRegistrationNumber(
        watchedCounterpartyVatNumber,
        watchedCounterpartyRegistrationNumber
      ),
    [watchedCounterpartyRegistrationNumber, watchedCounterpartyVatNumber]
  );

  const isCounterpartyVatValid = useMemo(
    () =>
      validateVatNumber(
        watchedCounterpartyVatNumber,
        watchedCounterpartyRegistrationNumber
      ),
    [
      watchedCounterpartyVatNumber,
      watchedCounterpartyRegistrationNumber,
      isCounterpartyRegistrationNumberValid
    ]
  );

  const handleAutocompleteChange = useCallback(
    (value: string, event?: SyntheticEvent<Element, Event>) => {
      let counterpartyName;
      if (event) {
        const [inputValue] = (event.target as HTMLInputElement).value.split(
          ' ('
        );
        counterpartyName = inputValue;
      } else {
        const [inputValue] = value.split(' (');
        counterpartyName = inputValue;
      }

      const selectedOption = options.find(
        (option: CounterpartyDropDown) => option.name === counterpartyName
      );

      if (!selectedOption) {
        return;
      }

      setSelectedCounterPartyId(selectedOption.id || null);
      setValue('name', selectedOption.name || '');
      setValue('registrationNumber', selectedOption.registrationNumber || '');
      setValue(
        'withoutRegistrationNumber',
        selectedOption.withoutRegistrationNumber || false
      );
      setValue('vatNumber', selectedOption.vatNumber || '');
      clearErrors();
    },
    [options, setSelectedCounterPartyId, setValue, clearErrors]
  );

  const [selectedIbanId, setSelectedIbanId] = useState<string | undefined>();

  useEffect(() => {
    const validIban = watchedIbanList?.find((item) => item.isVerified);
    if (validIban) {
      // @ts-ignore tmpId is used for unique identification for each IBAN (even newly created ones)
      setSelectedIbanId(validIban.tmpId);
    }
  }, []);

  const appendIban = () => {
    append({
      ...INITIAL_IBAN_ITEM,
      ...COUNTERPARTY_IBAN_ADDITIONAL_INITIAL_FIELDS,
      // @ts-ignore tmpId is used for unique identification for each IBAN (even newly created ones)
      tmpId: uuidv4()
    });
  };

  const removeIban = (index: number) => {
    remove(index);
    trigger();
  };

  const updateCounterPartyIban = useCallback(
    (newIban: Iban) => {
      const value = !newIban.isVerified;
      const updatedIbanList = watchedIbanList?.map((item) => {
        // @ts-ignore tmpId is used for unique identification for each IBAN (even newly created ones)
        return item.tmpId === newIban.tmpId
          ? {
              ...newIban,
              isVerified: value
            }
          : { ...item, isVerified: false };
      });

      setValue('ibanList', updatedIbanList);
      // @ts-ignore tmpId is used for unique identification for each IBAN (even newly created ones)
      setSelectedIbanId(value ? newIban.tmpId : undefined);
    },
    [setSelectedIbanId, setValue, watchedIbanList]
  );

  useEffect(() => {
    if (watchedWithoutRegistrationNumber) {
      setValue('registrationNumber', '');
    }
  }, [watchedWithoutRegistrationNumber]);

  useEffect(() => {
    // TODO: CHANGE THIS TO WORK WITH REACT HOOK FORM !!!
    const expenseType = getValues('expenseTypeId');

    if (!expenseType) {
      setError('expenseTypeId', {
        type: 'required',
        message: translate('errors.expenseType')
      });
    }
  }, [getValues, setError, translate]);

  useEffect(() => {
    setIsFormValid(formState.isValid);
  }, [formState.isValid]);

  return (
    <Box sx={{ pt: 2.5 }}>
      <form className="supplier-form" onSubmit={handleSubmit(handleFormSubmit)}>
        <Box sx={verificationFormFields}>
          <Box
            sx={{
              ...verificationFormFieldsWrapper,
              height: '55px'
            }}
          >
            <Controller
              // @ts-ignore
              name="selectedCounterPartyId"
              control={control}
              render={({ field }) => (
                <FormControl sx={verificationFormItem}>
                  <Autocomplete
                    open={open}
                    disableClearable
                    blurOnSelect
                    clearOnBlur={false}
                    forcePopupIcon={false}
                    onInputChange={(event, value) => {
                      handleAutocompleteChange(value);
                    }}
                    onSelect={(event: SyntheticEvent) => {
                      handleAutocompleteChange('', event);
                    }}
                    onOpen={() => {
                      setOpen(true);
                    }}
                    onClose={() => {
                      setOpen(false);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    getOptionLabel={(option) =>
                      getCounterPartySearchOptionLabel(option)
                    }
                    options={options}
                    loading={notEnoughCharactersToSearch ? false : isLoading}
                    noOptionsText={
                      notEnoughCharactersToSearch
                        ? translate('messages.searchRequiredCharacters', {
                            numberOfCharacters: String(
                              COUNTERPARTY_SEARCH_MIN_CHARACTERS
                            )
                          })
                        : translate('labels.noCounterpartiesFound')
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        {...field}
                        label={translate('labels.counterparty')}
                        onChange={(event) => {
                          setSearchParams(event.target.value);
                        }}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment sx={{ ml: 1 }} position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              {isLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                            </InputAdornment>
                          )
                        }}
                      />
                    )}
                  />
                </FormControl>
              )}
            />
          </Box>
          <Box
            sx={{
              ...verificationFormFieldsWrapper,
              minHeight: '50px'
            }}
          >
            <FormControl sx={verificationFormItem}>
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label={`${translate('labels.name')}*`}
                    name="name"
                    placeholder={translate('labels.name')}
                    error={!!error}
                    helperText={error && translate('errors.counterpartyName')}
                  />
                )}
              />
            </FormControl>
          </Box>
          <Box sx={{ ...verificationFormFieldsWrapper }}>
            <Box sx={{ width: '100%', flex: 1 }}>
              <Controller
                name="registrationNumber"
                control={control}
                rules={{
                  validate: () => {
                    if (!watchedCounterpartyVatNumber) {
                      return (
                        isCounterpartyRegistrationNumberValid ||
                        translate('errors.counterpartyRegistrationNumberBG')
                      );
                    }
                    if (watchedCounterpartyVatNumber.startsWith('BG')) {
                      return (
                        isCounterpartyRegistrationNumberValid ||
                        translate('errors.counterpartyRegistrationNumberBG')
                      );
                    }

                    return (
                      watchedCounterpartyVatNumber === '' ||
                      watchedCounterpartyRegistrationNumber === ''
                    );
                  }
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!isCounterpartyRegistrationNumberValid}
                    label={translate('labels.companyRegistrationNumber')}
                    name="registrationNumber"
                    placeholder={translate(
                      'labels.counterpartyRegistrationNumber'
                    )}
                    helperText={
                      !isCounterpartyRegistrationNumberValid &&
                      // eslint-disable-next-line no-nested-ternary
                      (watchedCounterpartyVatNumber?.startsWith('BG')
                        ? translate('errors.counterpartyRegistrationNumberBG')
                        : watchedCounterpartyVatNumber === ''
                        ? translate('errors.counterpartyRegistrationNumberBG')
                        : translate(
                            'errors.counterpartyRegistrationNumberForeign'
                          ))
                    }
                    sx={{ ...verificationFormItem, width: '100%' }}
                    disabled={watchedWithoutRegistrationNumber}
                  />
                )}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <Controller
                name="withoutRegistrationNumber"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={!!field.value}
                        color="primary"
                      />
                    }
                    sx={{
                      mt: 0.5
                    }}
                    label={translate('labels.withoutCompanyRegistrationNumber')}
                  />
                )}
              />
            </Box>
          </Box>
          <Box sx={verificationFormFieldsWrapper}>
            <Controller
              name="vatNumber"
              control={control}
              rules={{
                validate: (value) => {
                  if (value?.startsWith('BG')) {
                    return (
                      isCounterpartyVatValid ||
                      translate('errors.counterpartyVatNumberEquality')
                    );
                  }
                  return true; // No validation error for non-BG VAT numbers
                }
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={translate('labels.vatNumber')}
                  name="vatNumber"
                  placeholder={translate('labels.vatNumber')}
                  sx={verificationFormItem}
                  error={!!field.value && !isCounterpartyVatValid}
                  helperText={
                    field.value &&
                    !isCounterpartyVatValid &&
                    translate('errors.counterpartyVatNumberEquality')
                  }
                />
              )}
            />
            <Controller
              name="expenseTypeId"
              control={control}
              rules={{
                required: translate('errors.expenseType')
              }}
              render={({ field, fieldState: { error } }) => (
                <FormControl error={!!error} sx={verificationFormItem}>
                  <InputLabel>{`${translate(
                    'labels.expenseType'
                  )}*`}</InputLabel>
                  <Select
                    {...field}
                    sx={{ textAlign: 'left' }}
                    label={`${translate('labels.expenseType')}*`}
                    error={!!error}
                    value={field.value || ''} // Ensure a fallback value
                  >
                    {expenseTypeOptions &&
                      expenseTypeOptions.map(
                        (expenseType) =>
                          expenseType.isActive && (
                            <MenuItem
                              key={expenseType.id}
                              value={expenseType.id}
                              disabled={!expenseType.isActive}
                            >
                              {expenseType.name}
                            </MenuItem>
                          )
                      )}
                  </Select>
                  <FormHelperText>
                    {error && translate('errors.expenseType')}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </Box>
          <Box
            sx={{
              ...verificationFormFieldsWrapper,
              minHeight: '50px',
              flexDirection: 'column',
              marginTop: 1
            }}
          >
            {watchedIbanList?.map((item, index) => (
              <Box
                sx={{ ...arrayFieldFormContainer, alignItems: 'center' }}
                // @ts-ignore tmpId is used for unique identification for each IBAN (even newly created ones)
                key={item.tmpId}
              >
                <Box>
                  <VerifyCounterPartyIbanButton
                    iban={item}
                    handleToggle={() => updateCounterPartyIban(item)}
                    isSelected={
                      // @ts-ignore tmpId is used for unique identification for each IBAN (even newly created ones)
                      !!(selectedIbanId && selectedIbanId === item.tmpId)
                    }
                  />
                </Box>
                <Box sx={{ ...verificationFormFields, width: '100%' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <Controller
                      name={`ibanList.${index}.iban`}
                      control={control}
                      rules={{
                        validate: (value) =>
                          value
                            ? validateIban(value) &&
                              uniqueIbanValidation(
                                translate,
                                getValues(`ibanList.${index}.iban`),
                                getValues('ibanList')
                              )
                            : true
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            trigger();
                          }}
                          label={translate('labels.iban')}
                          error={!!errors.ibanList && !!errors.ibanList[index]}
                          placeholder={translate('labels.iban')}
                          sx={smallVerificationFieldWidth}
                        />
                      )}
                    />
                    {errors.ibanList && errors.ibanList[index] && (
                      <FormHelperText error>
                        {!validateIban(
                          getValues(`ibanList.${index}.iban`) || ''
                        )
                          ? translate('errors.invalidIban')
                          : translate('errors.ibanUnique')}
                      </FormHelperText>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: 55
                  }}
                >
                  <IconButton
                    color="primary"
                    type="button"
                    onClick={() => removeIban(index)}
                  >
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                </Box>
              </Box>
            ))}
            <Box display="flex" justifyContent="center">
              <Button type="button" onClick={appendIban}>
                {`+ ${translate('buttons.add')}`}
              </Button>
            </Box>
          </Box>
        </Box>
        <Tooltip title={!isFormValid ? translate('errors.invalidData') : ''}>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: '2rem' }}>
            <Button
              variant="contained"
              type="submit"
              sx={submitButton}
              disabled={!isFormValid}
            >
              {translate('buttons.save')}
            </Button>
          </Box>
        </Tooltip>
      </form>
    </Box>
  );
};
