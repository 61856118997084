import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridCellParams,
  GridRowSelectionModel
} from '@mui/x-data-grid';
import { useAtom } from 'jotai';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useUserController } from 'api/controllers/UserController';

import { UserManage, UserRoles } from 'openapi';

import { CreateUpdateUserForm } from 'components/forms/CreateUpdateUserForm';
import { ListHoverGridCell } from 'components/shared/gridCells/ListHoverGridCell';
import { GridToolbar } from 'components/shared/GridToolbar/GridToolbar';
import { Modal } from 'components/shared/Modal/Modal';

import { useCompanies } from 'context/CompanyContext';
import { TokenExpirationModalContext } from 'context/TokenExpirationModalProvider';
import { useTranslations } from 'context/TranslationContext';

import { useModal } from 'hooks/useModal';

import {
  ACTIONS,
  CHECK,
  COMPANY_IDS,
  DEFAULT_GRID_ROW_HEIGHT
} from 'utils/constants/constants';
import { getUserManagementColumns } from 'utils/helpers/userHelper';

import { commonDataGridContainerStyle } from 'styles/components/DataGridStyle';
import { invoicesDataGrid } from 'styles/components/InvoicesDataGridStyle';
import { modalButtonsWrapper } from 'styles/pages/InvoiceVerificationStyle';

import { userAtom } from 'state/state';

export const UserManagement = () => {
  const { handleLogout } = useContext(TokenExpirationModalContext);
  const { translate } = useTranslations();
  const { getUsers, addUser, updateUser, deleteUsers } = useUserController();
  const { companiesList } = useCompanies();
  const [loggedUser] = useAtom(userAtom);

  const {
    openModal: openEditModal,
    closeModal: closeEditModal,
    isOpen: isEditModalOpen
  } = useModal();

  const {
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
    isOpen: isDeleteModalOpen
  } = useModal();

  const [users, setUsers] = useState<UserManage[] | null>(null);
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const [userForEdit, setUserForEdit] = useState<UserManage>();
  const [singleDeleteId, setSingleDeleteId] = useState<number | null>(null);

  const editModalTitle = useMemo(
    () =>
      userForEdit
        ? `${translate('labels.editUser')}: ${userForEdit.name}`
        : translate('labels.addUser'),
    [userForEdit, translate]
  );

  const userEmails = useMemo(
    () =>
      userForEdit
        ? users
            ?.filter((user) => user.id !== userForEdit.id)
            .map((user) => user.email)
        : users?.map((user) => user.email),
    [users, userForEdit]
  );

  const deleteList = useMemo(() => {
    if (singleDeleteId) {
      return [singleDeleteId];
    }
    return selectedRows;
  }, [singleDeleteId, selectedRows]);

  const isCurrentUserInDeleteList = useMemo(
    () => deleteList.includes(loggedUser?.id as number),
    [deleteList, loggedUser?.id]
  );

  const isOnlySuperAdmin = useMemo(
    () =>
      users?.filter((user) => user.role === UserRoles.SUPER_ADMIN).length === 1,
    [users]
  );

  const columns = useMemo(
    () =>
      getUserManagementColumns(translate).map((column) => {
        if (column.field === COMPANY_IDS) {
          return {
            ...column,
            renderCell: ({ row }: GridCellParams<UserManage>) => (
              <ListHoverGridCell
                list={row.companyIds
                  .map(
                    (id: number) =>
                      companiesList.find((company) => company.id === id)
                        ?.name || ''
                  )
                  .filter(Boolean)}
              />
            )
          };
        }
        if (column.field === ACTIONS) {
          return {
            ...column,
            renderCell: ({ row }: GridCellParams) => (
              <Grid container justifyContent="center">
                <Tooltip
                  title={
                    isOnlySuperAdmin && row.id === loggedUser?.id
                      ? translate('errors.isLastSuperAdmin', {
                          superAdminText: UserRoles.SUPER_ADMIN
                        })
                      : ''
                  }
                  placement="top"
                >
                  <Box>
                    <GridActionsCellItem
                      disabled={isOnlySuperAdmin && row.id === loggedUser?.id}
                      icon={
                        <DeleteTwoToneIcon
                          sx={(theme) => ({
                            color:
                              isOnlySuperAdmin && row.id === loggedUser?.id
                                ? 'default'
                                : theme.palette.error.main,
                            fontSize: '1.5rem'
                          })}
                          titleAccess={translate('labels.delete')}
                        />
                      }
                      label={translate('labels.delete')}
                      onClick={() => {
                        openDeleteModal();
                        setSingleDeleteId(row.id);
                      }}
                    />
                  </Box>
                </Tooltip>
              </Grid>
            )
          };
        }
        return column;
      }),
    [translate, companiesList, loggedUser?.id, openDeleteModal]
  );

  const fetchUsers = useCallback(async () => {
    const result = await getUsers();
    setUsers(result);
  }, [getUsers]);

  const handleAddClick = () => {
    setUserForEdit(undefined);
    openEditModal();
  };

  const handleEditClick = (params: GridCellParams) => {
    if (params.field !== CHECK && params.field !== ACTIONS) {
      const user = users?.find((userFromList) => userFromList.id === params.id);
      setUserForEdit(user);
      openEditModal();
    }
  };

  const handleCloseEditModal = () => {
    closeEditModal();
    setUserForEdit(undefined);
  };

  const handleSave = useCallback(
    async (user: UserManage) => {
      handleCloseEditModal();
      const trimmedUser = {
        ...user,
        email: user.email.trim(),
        name: user.name.trim()
      };
      if (userForEdit) {
        await updateUser(userForEdit.id!, trimmedUser);
        if (userForEdit.id === loggedUser?.id) {
          await handleLogout();
        }
      } else {
        await addUser(trimmedUser);
      }
      await fetchUsers();
    },
    [userForEdit, addUser, updateUser, fetchUsers]
  );

  const handleDelete = async () => {
    await deleteUsers(deleteList as number[]);
    if (isCurrentUserInDeleteList) {
      await handleLogout();
      return;
    }
    setSingleDeleteId(null);
    setSelectedRows([]);
    closeDeleteModal();
    await fetchUsers();
  };

  const handleCloseDeleteModal = () => {
    setSingleDeleteId(null);
    closeDeleteModal();
  };

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const isMassDeleteDisabled = useMemo(() => {
    const remainingUsers = users?.filter(
      (user) => !deleteList.includes(Number(user.id))
    );
    return !remainingUsers?.some((user) => user.role === UserRoles.SUPER_ADMIN);
  }, [loggedUser?.id, deleteList, users]);

  return users ? (
    <Paper elevation={4} sx={commonDataGridContainerStyle}>
      <DataGrid
        rows={users || []}
        columns={columns}
        checkboxSelection
        rowHeight={DEFAULT_GRID_ROW_HEIGHT}
        onCellClick={handleEditClick}
        pageSizeOptions={[]}
        disableRowSelectionOnClick
        sx={invoicesDataGrid}
        onRowSelectionModelChange={setSelectedRows}
        slots={{
          toolbar: GridToolbar
        }}
        slotProps={{
          toolbar: {
            handleAddClick,
            handleDeleteClick: openDeleteModal,
            selectedRows,
            isDefaultToolbarHidden: true,
            entries: users,
            isDeleteDisabled: isMassDeleteDisabled,
            deleteButtonTooltip: translate('errors.isLastSuperAdmin', {
              superAdminText: UserRoles.SUPER_ADMIN
            })
          }
        }}
        localeText={{
          noRowsLabel: translate('labels.noData')
        }}
      />
      {isDeleteModalOpen && deleteList.length > 0 && (
        <Modal
          headerTitle={translate('titles.delete')}
          isOpen={isDeleteModalOpen}
          hide={handleCloseDeleteModal}
          maxWidth="sm"
        >
          <Typography variant="body1" mb={2}>
            {selectedRows.length === 1 || singleDeleteId
              ? translate('messages.deleteUserConfirmation')
              : translate('messages.deleteUsersConfirmation', {
                  userNames: deleteList
                    .map((id) => users?.find((user) => user.id === id)?.name)
                    .join(', ')
                })}
          </Typography>
          {isCurrentUserInDeleteList && (
            <Typography
              variant="body1"
              sx={(theme) => ({ color: theme.palette.error.main })}
              mb={2}
            >
              {translate('messages.deleteUserSelf')}
            </Typography>
          )}
          <Box sx={modalButtonsWrapper}>
            <Button variant="outlined" onClick={handleCloseDeleteModal}>
              {translate('buttons.cancel')}
            </Button>
            <Button variant="contained" onClick={handleDelete}>
              {translate('buttons.delete')}
            </Button>
          </Box>
        </Modal>
      )}
      <Modal
        isOpen={isEditModalOpen}
        hide={handleCloseEditModal}
        headerTitle={editModalTitle}
        maxWidth="sm"
      >
        <CreateUpdateUserForm
          initialValues={userForEdit}
          isOnlySuperAdmin={!userForEdit?.isDeletable}
          userEmails={userEmails!}
          handleSave={handleSave}
        />
      </Modal>
    </Paper>
  ) : (
    <Stack alignItems="center" justifyContent="center" height="100%">
      <CircularProgress />
    </Stack>
  );
};
