import { FileRejection } from 'react-dropzone';
import { toast } from 'react-toastify';

const constructMessage = (files: string[]) => {
  let message = '';

  files.forEach((file, index) => {
    if (files.length === 1) {
      message += `${file}\n`;
    } else {
      message += `${index + 1}. ${file}\n`;
    }
  });

  return message;
};

export const getInvoiceWarningMessage = (invoices: string) => {
  const invoicesArray = invoices.split(', ');
  const message = constructMessage(invoicesArray);

  const label =
    invoicesArray.length === 1
      ? 'warnings.invoiceDuplicate'
      : 'warnings.invoicesDuplicate';

  return {
    label,
    message
  };
};

export const getUnsupportedFormatMessage = (files: FileRejection[]) => {
  const filesNames = files.map((file) => file.file.name);
  const message = constructMessage(filesNames);

  const label =
    filesNames.length === 1
      ? 'warnings.invoiceNotSupported'
      : 'warnings.invoicesNotSupported';

  return {
    label,
    message
  };
};

export const showWarnMessage = (message: string) => {
  toast.warn(message, {
    position: toast.POSITION.BOTTOM_RIGHT,
    style: {
      textAlign: 'left',
      whiteSpace: 'pre-line',
      wordBreak: 'break-all'
    }
  });
};
